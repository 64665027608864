import { useDismiss } from '../../../Hooks';
import {
	DropDown,
	DropDownList,
	Option,
	CurrentOption,
	SearchInput,
	LanguageIcon,
	DropDownArrow,
} from './Styles';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import languageIcon from '../../../assets/images/language_icon.svg';
import dropdownIcon from '../../../assets/images/arrow_right_blue.png';
import { setAllAnswers, setLanguage } from '../../../redux/actions';
import { setLocal } from '../../../Utils';

const DropDownComponent = ({
	language,
	languages,
	setLanguage,
	width = '160px',
	height = '38px',
	children,
	text,
	modalOverflow = false,
}) => {
	const [expanded, setExpanded] = useState(false);
	const [filterInput, setFilterInput] = useState('');
	const inside = useRef(null);
	const filterInputRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const renderOptions = () => {
		return languages.map(({ option, id }, index) => {
			return (
				<Option
					title={option}
					key={index}
					height={height}
					highlighted={
						language.language === option || index === selectedIndex
					}
					onClick={(e) => {
						e.stopPropagation();
						setLanguage({ language: option, id });
						setLocal('language', { language: option, id });
						setExpanded(false);
					}}
				>
					{option}
				</Option>
			);
		});
	};

	useDismiss(inside, () => setExpanded(false));

	// make sure input field is focused when user click on dropdown.
	useEffect(() => {
		if (filterInputRef && expanded && filterInput.current) {
			filterInputRef.current.focus();
		}
	}, [expanded]);

	const handleEnterPress = (e) => {
		if (e.key === 'Enter' && languages.length > selectedIndex) {
			setLanguage({ language: languages[selectedIndex].option });
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (selectedIndex < languages.length - 1) {
				setSelectedIndex(selectedIndex + 1);
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (selectedIndex > 0) {
				setSelectedIndex(selectedIndex - 1);
			}
		}
	};

	const handleClick = (e) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	return (
		<DropDown
			expanded={expanded}
			ref={inside}
			width={width}
			height={height}
		>
			<CurrentOption
				expanded={expanded}
				onClick={(e) => handleClick(e)}
				height={height}
			>
				{children}
				<LanguageIcon
					height={height}
					src={languageIcon}
					alt={'language'}
				/>
				<span>{language.language}</span>
				<DropDownArrow
					height={height}
					src={dropdownIcon}
					alt={'dropdown'}
				/>
			</CurrentOption>
			{expanded && (
				<DropDownList
					modalOverflow={modalOverflow}
					width={width}
					height={height}
				>
					{renderOptions()}
				</DropDownList>
			)}
		</DropDown>
	);
};

const mapStateToProps = (state) => ({
	language: state.language,
	languages: state.languages,
});

const mapDispatchToProps = {
	setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDownComponent);
