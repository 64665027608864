export const SET_ANSWER = 'SET_ANSWER';
export const SET_ALL_ANSWERS = 'SET_ALL_ANSWERS';
export const SEND = 'SEND';
export const TOGGLE_EXPANDABLE = 'TOGGLE_EXPANDABLE';
export const EXPAND_ALL = 'EXPAND_ALL';
export const PRINT_FINISHED = 'PRINT_FINISHED';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setAnswer = (data) => ({
	type: SET_ANSWER,
	data,
});

export const setAllAnswers = (data) => ({
	type: SET_ALL_ANSWERS,
	data,
});

export const send = (data) => ({
	type: SEND,
	data,
});

export const toggleExpandable = (data) => ({
	type: TOGGLE_EXPANDABLE,
	data,
});

export const expandAll = (data) => ({
	type: EXPAND_ALL,
	data,
});

export const printFinished = (data) => ({
	type: PRINT_FINISHED,
	data,
});

export const setToken = (data) => ({
	type: SET_TOKEN,
	data,
});

export const setLanguage = (data) => ({
	type: SET_LANGUAGE,
	data,
});
