import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../survey/CustomButton';
import EmailCustomization from '../EmailCustomization';
import Info from '../Info';
import { createSurvey } from '../../../services/APIservice';
import { Redirect, Link } from 'react-router-dom';
import './createSurvey.css';
import { AppContainer, AppDiv } from '../../../Styles';
import SurveyCard from '../../shared/SurveyCard';
import { CreateSurveyHeader, ButtonRow } from './styles';
import Footer from '../../shared/Footer';

const CreateSurvey = () => {
	// Survey Data
	const [name, setName] = useState('');
	const [deadline, setDeadline] = useState('');
	const [minimumAnswers, setMinimumAnswers] = useState(5);
	const [csvContent, setCSVContent] = useState(undefined);
	const [emailField, setEmailField] = useState('');
	const [additionalAttributes, setAdditionalAttributes] = useState({});
	const [emailHeader, setEmailHeader] = useState(
		'Osallistu työpaikkasi motivaatio-kyselyyn',
	);
	const [emailContent, setEmailContent] = useState(
		'Teidät on kutsuttu vastaamaan MOTI - työhyvinvointi- ja motivaatiokyselyyn.',
	);
	const [emailImage, setEmailImage] = useState('');
	const [color, setColor] = useState('#0363ff');

	// UI functionality
	const [emailFieldSelection, setEmailFieldSelection] = useState('');
	const [readyToReadCSV, setReadyToReadCSV] = useState(false);
	const [errorsFromServer, setErrorFromServer] = useState('');
	const [sent, setSent] = useState(false);

	// error messages
	const [errors, setErrors] = useState({
		name: '',
		deadline: '',
		csv: '',
		emailField: '',
	});
	console.log('create_survey');
	const history = useHistory();

	useEffect(() => {
		if (readyToReadCSV) {
			const file = document.getElementById('answererList').files[0];
			if (!file) {
				setReadyToReadCSV(false);
				return;
			}
			parseCSV(file)
				.then((result) => {
					setCSVContent(result);

					const base = {
						efsOptions: [
							<option
								key={'default-efs'}
								value={''}
								hidden
								disabled
								defaultValue
							>
								-- Valitse --
							</option>,
						],
						aasValues: {},
					};

					const helper = result[0].reduce((temp, nextHeader) => {
						temp.efsOptions.push(
							<option
								key={nextHeader + '-efs'}
								value={nextHeader}
							>
								{nextHeader}
							</option>,
						);
						temp.aasValues[nextHeader] = false;

						return temp;
					}, base);

					setEmailFieldSelection(helper.efsOptions);
					setAdditionalAttributes(helper.aasValues);
				})
				.catch((error) => {
					setErrorFromServer(error);
				});
			setReadyToReadCSV(false);
		}
	}, [readyToReadCSV]);

	const createNewSurvey = () => {
		if (!valid()) {
			return;
		}

		const colorToUse = /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color)
			? color
			: '#0363ff';

		const surveyData = {
			name,
			deadline,
			minimumAnswers,
			csvContent,
			emailField,
			additionalAttributes,
			emailHeader,
			emailContent,
			emailHTML: `<html><head/><body><div style='display: flex; width: 100%; background-color: #e6e6e6;'><div style='background-color: ${colorToUse};max-width: 600px;margin: auto;'><div style='background-color: #FFFFFF;margin-top: 40px;text-align: center;'>${
				emailImage
					? `<img src='${emailImage}' alt='logo' style='width: 150px;margin-top: 40px;'></img>`
					: ''
			}<h3 style='margin-left: 20px;margin-right: 20px;'>${emailHeader}</h3><p style='margin-left: 20px;margin-right: 20px;'>${emailContent}</p><a href='<URL_PLACEHOLDER>' style='width: 100px;height: 30px;border: none;background-color: ${colorToUse};color: white;margin-bottom: 40px;margin-top: 30px;display: inline-block; padding-top: 10px; text-decoration: none'>ALOITA!</a></div></div></div></body></html>`,
		};
		createSurvey(surveyData)
			.then((response) => {
				setSent(true);
			})
			.catch((error) => {
				setErrorFromServer(error.response.data);
			});
	};

	const validateMinimumAnswers = (value) => {
		if (isNaN(value) || value < 1) {
			setMinimumAnswers(1);
		}
	};

	const valid = () => {
		const temp = { name: '', deadline: '', csv: '', emailField: '' };

		let scrollTarget = '';

		if (!name) {
			temp.name = 'nimi ei voi olla tyhjä';
			scrollTarget = 'name';
		}
		if (!deadline) {
			temp.deadline = 'Kyselyn päättymispäivä ei voi olla tyhjä';
			if (!scrollTarget) {
				scrollTarget = 'deadline';
			}
		} else if (new Date() > new Date(deadline)) {
			temp.deadline =
				'Kyselyn päättymispäivän pitää olla tulevaisuudessa';
			if (!scrollTarget) {
				scrollTarget = 'deadline';
			}
		}
		if (!document.getElementById('answererList').files[0]) {
			temp.csv = 'Kyselyn vastaajat -CSV vaaditaan';
			if (!scrollTarget) {
				scrollTarget = 'answererCSV';
			}
		}
		if (!emailField) {
			temp.emailField = 'sähköpostikenttä ei voi olla tyhjä';
			if (!scrollTarget) {
				scrollTarget = 'emailField';
			}
		}

		if (scrollTarget) {
			document.getElementById(scrollTarget).scrollIntoView({
				behavior: 'smooth',
			});
		}

		setErrors(temp);

		return Boolean(name && deadline && emailField);
	};

	const handleChange = (target, value) => {
		switch (target) {
			case 'name':
				setName(value);
				setErrors({ ...errors, name: '' });
				break;
			case 'deadline':
				setDeadline(value);
				setErrors({ ...errors, deadline: '' });
				break;
			case 'answererCSV':
				setReadyToReadCSV(true);
				setErrors({ ...errors, csv: '' });
				break;
			case 'emailField':
				setEmailField(value);
				setErrors({ ...errors, emailField: '' });
				break;
			default:
				console.warn('invalid target');
				break;
		}
	};

	const showContentAfterFileSubmit = emailFieldSelection ? (
		<>
			<span className="createSurveyLabel" id="emailField">
				Valitse sähköpostikenttä: <Info field="emailField" />
			</span>
			<br />
			<select
				className="createSurveyInput"
				name="emailField"
				id="emailField"
				onChange={(e) => handleChange('emailField', e.target.value)}
				value={emailField}
			>
				{emailFieldSelection}
			</select>
			{errors.emailField ? (
				<p className="errorMessage">{errors.emailField}</p>
			) : (
				''
			)}
			<br />
			<span className="createSurveyLabel">
				Valitse ylimääräiset kentät tulosten vertailuun:{' '}
				<Info field="additionalAttributes" />
			</span>
			<br />
			<div className="createSurveyInput">
				{Object.keys(additionalAttributes).map((attr) => (
					<CustomCheckBox
						key={attr}
						id={attr}
						value={attr}
						handleChange={() => toggleAttribute(attr)}
					/>
				))}
			</div>
			<EmailCustomization
				emailContent={emailContent}
				setEmailContent={setEmailContent}
				emailHeader={emailHeader}
				setEmailHeader={setEmailHeader}
				color={color}
				setColor={setColor}
				setEmailImage={setEmailImage}
			/>
		</>
	) : (
		''
	);

	const toggleAttribute = (key) => {
		const current = {
			...additionalAttributes,
			[key]: !additionalAttributes[key],
		};
		setAdditionalAttributes(current);
	};

	const errorsFromServerAsList = errorsFromServer ? (
		<>
			<h3 style={{ color: 'red' }}>Server responded with error</h3>
			<ul>
				{errorsFromServer.map((e, index) => (
					<li key={index}>
						<p className="errorMessage">{e}</p>
					</li>
				))}
			</ul>
		</>
	) : (
		''
	);

	return sent ? (
		<Redirect to="/" />
	) : (
		<SurveyCard>
			<CustomButton
				id={'previous'}
				onClick={() => history.push('/')}
				text={'takaisin'}
			/>
			{errorsFromServerAsList}
			<CreateSurveyHeader>Luo uusi kysely</CreateSurveyHeader>
			<span className="createSurveyLabel" id="name">
				Kyselyn nimi:
			</span>
			<br />
			<input
				type="text"
				className="createSurveyInput"
				id="name"
				value={name}
				onChange={(e) => handleChange('name', e.target.value)}
				autoComplete="off"
			/>
			{errors.name ? <p className="errorMessage">{errors.name}</p> : ''}
			<br />
			<span className="createSurveyLabel" id="deadline">
				Kyselyn päättymispäivämäärä:
			</span>
			<br />
			<input
				type="date"
				className="createSurveyInput"
				id="deadline"
				value={deadline}
				onChange={(e) => handleChange('deadline', e.target.value)}
			/>
			{errors.deadline ? (
				<p className="errorMessage">{errors.deadline}</p>
			) : (
				''
			)}
			<br />
			<span className="createSurveyLabel">
				Vastausten minimimäärä tulosten näkemiseksi:{' '}
				<Info field="minimumAnswers" />
			</span>
			<br />
			<input
				type="number"
				className="createSurveyInput"
				id="minimum"
				value={minimumAnswers}
				onChange={(e) => setMinimumAnswers(e.target.value)}
				onBlur={(e) => validateMinimumAnswers(e.target.value)}
			/>
			<br />
			<span className="createSurveyLabel" id="answererCSV">
				Kyselyn vastaajat CSV-tiedostona: <Info field="csv" />
			</span>
			<br />
			<input
				type="file"
				className="createSurveyInput"
				id="answererList"
				onChange={(e) => handleChange('answererCSV', '')}
				accept=".csv"
			/>
			{errors.csv ? <p className="errorMessage">{errors.csv}</p> : ''}
			<br />
			{showContentAfterFileSubmit}
			<ButtonRow>
				<CustomButton
					id="createSurveyButton"
					text="Luo kysely"
					onClick={createNewSurvey}
				/>
			</ButtonRow>
		</SurveyCard>
	);
};

const CustomCheckBox = ({ id, value, handleChange }) => {
	return (
		<>
			<input
				type="checkBox"
				id={id}
				name={value}
				value={value}
				onChange={handleChange}
			/>
			<label htmlFor={id}>{value}</label>
			<br />
		</>
	);
};

const parseCSV = async (csv) => {
	return new Promise(async (resolve, reject) => {
		if (typeof window.FileReader !== 'function') {
			reject(
				'Toiminto vaatii "file API":a tukevan selaimen; käytä toista selainta',
			);
		}

		let text = await readFile(csv);
		text = text.replace(/"|\r/g, '');
		let rows = text.split('\n');

		rows = rows.map((row) => row.split(';'));
		//Removes the last line of csv if it is empty
		rows =
			rows[rows.length - 1].length === 1 &&
			rows[rows.length - 1][0] === ''
				? rows.slice(0, rows.length - 1)
				: rows;

		const result = rowsToObjects(rows);
		resolve(result);
	});
};

const readFile = async (csv) => {
	return new Promise((resolve, reject) => {
		const fr = new FileReader();
		fr.onload = (e) => {
			resolve(e.target.result);
		};
		fr.readAsText(csv);
	});
};

const rowsToObjects = (rows) => {
	const headers = rows[0];
	const objects = rows.slice(1).map((row) => {
		const result = {};
		for (let i = 0; i < row.length; i++) {
			result[headers[i]] = row[i];
		}
		return result;
	});
	return [rows[0], ...objects];
};

export default CreateSurvey;
