import styled from 'styled-components';
import { color, font } from '../../../Styles';

export const CreateSurveyHeader = styled.h1`
	${font.PBReg};
	color: ${color.primary};
`;

export const ButtonRow = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
`;
