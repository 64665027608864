import styled from 'styled-components';
import { color, units, font } from '../../../Styles';

export const SurveyContainer = styled.div`
	display: ${(props) => (props.hidden ? 'none' : 'flex')};
	flex-direction: column;
`;

export const SurveyTitleDiv = styled.div`
	width: 100%;
	display: flex;
	height: 54px;
	margin-bottom: 10px;
`;

export const SurveyTitle = styled.h2`
	color: ${color.primary};
	${font.DCBold};
	font-size: 30px;
	margin: auto 0 0 0;
	letter-spacing: 1px;
	text-transform: uppercase;
	@media (max-width: ${units.mobile}) {
		font-size: calc(3vw + 16px);
	}
`;

export const SurveyLogoImg = styled.img`
	height: 50px;
	position: relative;
	right: 0;
	margin: 0 0 auto auto;
`;

export const SurveySubtitleDiv = styled.div`
	border-top: 1px solid ${color.BG2};
	border-bottom: 1px solid ${color.BG2};
	width: 100%;
	${font.PBReg};
	font-weight: lighter;
	margin: 10px 0;
	display: flex;
`;

export const SurveySubtitle = styled.span`
	color: ${color.primary};
	padding: 1em 0;
	font-size: 20px;
`;

export const SurveyDescriptionDiv = styled.div`
	width: 100%;
`;

export const SurveyDescription = styled.p`
	${font.PBReg};
	font-weight: 200;
	letter-spacing: 0.5px;
	margin: 1em 0;
	line-height: 1.5em;
	font-size: 16px;
`;
