import styled from 'styled-components';
import { color, font, units } from '../../../Styles';

export const SummaryContainerDiv = styled.div`
	min-height: 100vh;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	background-color: ${color.BG1};
`;

export const SummaryHeader = styled.h1`
	color: ${color.primary};
	${font.PBReg};
	font-size: 64px;
	font-weight: lighter;
	@media (max-width: ${units.tablet}) {
		font-size: calc(30px + 2vw);
	}
	@media (max-width: ${units.mobile}) {
		font-size: calc(20px + 4vw);
	}
`;

export const SummaryHeaderText = styled.p`
	${font.PBReg};
`;

export const SummaryFeedDiv = styled.div`
	width: 80%;
	background-color: ${color.BG1};
	margin: 5% 10% auto 10%;
	display: flex;
	flex-direction: column;
	@media (max-width: ${units.mobile}) {
		width: 90%;
		margin: 5% 5% auto 5%;
	}
`;
