import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../survey/CustomButton';
import { Link, Redirect } from 'react-router-dom';
import { login } from '../../../services/APIservice';
import { setToken } from '../../../redux/actions';
import {
	AnimatedLoginInput,
	ButtonRow,
	LoginButton,
	LoginContentContainer,
	LoginHeader,
	SignUpButton,
} from './styles';
import { AppContainer, AppDiv } from '../../../Styles';
import SurveyCard from '../../shared/SurveyCard';
import Footer from '../../shared/Footer';

const Login = ({ token, setToken }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [done, setDone] = useState(Boolean(token));
	const history = useHistory();

	const loginSubmit = () => {
		login(email, password)
			.then((response) => {
				window.localStorage.setItem('token', response.data.token);
				setToken({ token: response.data.token });
				setDone(true);
			})
			.catch((error) => {
				alert('login failed!');
			});
	};

	return done ? (
		<Redirect to="/" />
	) : (
		<SurveyCard>
			<LoginContentContainer>
				<LoginHeader>Kirjaudu sisään</LoginHeader>
				<AnimatedLoginInput>
					<input
						name={'username'}
						type="text"
						autoComplete={'newpassword'}
						value={email}
						onKeyDown={(e) => {
							if (e.key === 'Enter') loginSubmit();
						}}
						required
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label htmlFor={'username'}>
						<span>Käyttäjätunnus</span>
					</label>
				</AnimatedLoginInput>
				<br></br>
				<AnimatedLoginInput>
					<input
						type="password"
						value={password}
						required
						onKeyDown={(e) => {
							if (e.key === 'Enter') loginSubmit();
						}}
						name={'password'}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<label htmlFor={'password'}>
						<span>Salasana</span>
					</label>
				</AnimatedLoginInput>

				<br></br>
				<ButtonRow>
					{/*<SignUpButton onClick={() => history.push('/signup')}>*/}
					{/*	<Link to="/signup">Luo uusi käyttäjä</Link>*/}
					{/*</SignUpButton>*/}
					<LoginButton
						id="login"
						text="Kirjaudu"
						onClick={loginSubmit}
					>
						Kirjaudu
					</LoginButton>
				</ButtonRow>
			</LoginContentContainer>
		</SurveyCard>
	);
};

const mapStateToProps = (state) => ({
	token: state.token,
});

const mapDispatchToProps = {
	setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
