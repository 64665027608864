import React from 'react';
import SurveyCard from '../../shared/SurveyCard';
import { ErrorText } from './styles';
import { connect } from 'react-redux';

const Error404 = (language) => {
	const errorLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return '404 Sidan kunde inte hittas';
			case 'Suomi':
				return '404 Hakemaasi sivua ei löytynyt';
			default:
				return '404 The page could not be found';
		}
	};

	return (
		<SurveyCard>
			<ErrorText>{errorLanguage()}</ErrorText>
		</SurveyCard>
	);
};

const mapStateToProps = (state) => ({
	language: state.language,
});
export default connect(mapStateToProps)(Error404);
