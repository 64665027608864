import styled, { css } from 'styled-components';
import { color, cursor, font, units } from '../../../Styles';

export const SurveyListItemCol = styled.div`
	width: calc(50% - 10px);
	margin: 0 0 20px 0;
	${(props) => {
		if (props.enabled) {
			return css`
				${cursor.clickable};
				&:hover {
					background-color: ${color.BG1};
					box-shadow: 0 0 10px ${color.BG2};
					transform: scale(1.01);
					transition: all 0.2s ease-in-out;
				}
			`;
		} else {
			return css`
				${cursor.notAllowed};
			`;
		}
	}}
	@media (max-width: ${units.mobile}) {
		width: 100%;
	}
`;

export const DeleteSurveyButton = styled.button`
	color: #6b5050;
	position: absolute;
	${cursor.clickable};
	${font.RBold};
	border: none;
	font-size: 18px;
	background-color: ${color.BG1}00;
	&:hover {
		color: #6b0000;
	}
`;

export const SurveyListItemTable = styled.table`
	display: table;
	background-color: ${color.BG1};
	width: calc(100%);
	height: 100%;
	table-layout: fixed;
	padding-bottom: 5px;
	box-shadow: 0 0 5px ${color.BG2};
	border-radius: 5px;
`;

export const SurveyListItemHeader = styled.h2`
	text-align: center;
	${font.DCBold};
	margin-bottom: 10px;
	color: ${color.primary};
`;

export const SurveyListItemInfo = styled.td`
	display: table-cell;
	${font.RReg};
`;

export const DoughnutChartContainer = styled.td`
	display: table-cell;
	width: 50%;
`;
