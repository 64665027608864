import styled from 'styled-components';
import { color, components, cursor, font, units } from '../../../Styles';

export const LoginContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: 0 auto;
	@media (max-width: ${units.mobile}) {
		width: 100%;
	}
`;

export const LoginHeader = styled.h1`
	${font.title};
	margin: 0px auto 10px 0;
`;

export const AnimatedLoginInput = styled.div`
	${components.animatedLabeledInput};
`;

export const ButtonRow = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const LoginButton = styled.button`
	${components.buttonWhite};
	margin-top: 10px;
`;

export const SignUpButton = styled.button`
	${components.buttonWhite};
	margin-top: 10px;
`;
