import React, { useState, useEffect } from 'react';
import {
	getAverages,
	getAveragesWithFilter,
} from '../../../services/APIservice';
import LineChart from '../charts/LineChart';
import { AveragesTitle } from './styles';

const Averages = ({ surveyId, filters, setErrorMessage }) => {
	const [loading, setLoading] = useState(true);
	const [averages, setAverages] = useState({
		all: { field1: 0, field2: 0, field3: 0, field4: 0 },
	});
	const [fetched, setFetched] = useState({});

	useEffect(() => {
		getAverages('surveyId', surveyId)
			.then((results) => {
				setAverages({ all: results });
				setLoading(false);
			})
			.catch((error) => {
				setErrorMessage(error.response);
			});
	}, [surveyId, setErrorMessage]);
	const handleChange = (checkbox) => {
		if (!fetched[checkbox.value]) {
			getAveragesWithFilter(surveyId, checkbox.value)
				.then((response) => {
					setAverages({
						...averages,
						...response.data,
					});
					setFetched({
						...fetched,
						[checkbox.value]: { ...response.data },
					});
				})
				.catch((error) => {
					setErrorMessage(error);
				});
		} else {
			if (checkbox.checked) {
				setAverages({
					...averages,
					...fetched[checkbox.value],
				});
			} else {
				const temp = { ...averages };
				Object.keys(fetched[checkbox.value]).forEach((key) => {
					delete temp[key];
				});
				setAverages(temp);
			}
		}
	};

	const menu = filters.map((filter) => {
		return (
			<div key={filter}>
				<input
					type="checkbox"
					id={filter}
					name={filter}
					value={filter}
					onChange={(e) => handleChange(e.target)}
				/>
				<label htmlFor={filter}> {filter}</label>
			</div>
		);
	});

	return (
		<>
			<AveragesTitle>Vastausten keskiarvot</AveragesTitle>
			<h4>Ryhmittele vastauksia</h4>
			{menu}
			<LineChart content={averages} />
		</>
	);
};

export default Averages;
