import React from 'react';
import { connect } from 'react-redux';

const Feedback = ({ options, answer, language }) => {
	let result = '';
	if (!answer) {
		return '';
	} else if (answer < 3) {
		result = 'low';
	} else if (answer < 6) {
		result = 'mid';
	} else {
		result = 'high';
	}

	const followUps = options[result].followUpQuestions.map((q, i) => (
		<li key={i}>{q}</li>
	));

	return (
		<div className="feedback">
			<p>{options[result].content}</p>
			<ul>{followUps}</ul>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	language: state.language,
	options: state.survey.sections
		.find((s) => s.title === ownProps.section)
		.questions.find((q) => q.id === ownProps.questionId).feedback,
	answer: state.answers[ownProps.section + '-' + ownProps.questionId],
});

export default connect(mapStateToProps)(Feedback);
