import styled from 'styled-components';
import { color, font, units } from '../../../Styles';

export const OptionContainer = styled.div`
	width: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
	z-index: 2;
`;

export const Question = styled.p`
	${font.PBReg};
	font-size: 16px;
	line-height: 1.5em;
	letter-spacing: 1px;
	padding-bottom: 1em;
`;

export const OptionStrikeThrough = styled.div`
	width: 84%;
	height: 2px;
	margin: 0 auto;
	top: calc(33px);
	z-index: 1;
	background-color: ${color.primary};
	position: relative;
	@media (max-width: ${units.tablet}) {
		top: calc(3px + 4vw);
	}
`;

export const MinEndpoint = styled.p`
	display: inline-block;
	margin-left: 18px;
	color: ${color.BG2};
	text-transform: uppercase;
	${font.DCBold};
`;

export const MaxEndpoint = styled.p`
	float: right;
	margin-right: 18px;
	color: ${color.BG2};
	text-transform: uppercase;
	${font.DCBold};
`;
