import styled from 'styled-components';
import { color, units, font } from '../../../Styles';

export const SurveyQuestionDiv = styled.div`
	width: 80%;
	background-color: ${color.BG2};
	margin: 5% 10% auto 10%;
	display: flex;
	@media (max-width: ${units.mobile}) {
		width: 90%;
		margin: 5% 5% auto 5%;
	}
`;

export const NavigationRowDiv = styled.div`
	width: 100%;
	display: flex;
	padding-top: 5%;
	border-top: 1px solid ${color.BG2};
`;

export const NextButtonDiv = styled.div`
	width: 40%;
	display: flex;
	& button {
		margin-left: auto;
	}
`;

export const PrevButtonDiv = styled.div`
	width: 40%;
	& button {
		margin-right: auto;
	}
`;

export const CurrentQuestionDiv = styled.div`
	width: 20%;
	font-size: 18px;
	padding-top: 3px;
	${font.DCBold};
	text-align: center;
`;
