import survey from './../survey.json';
import {
	SET_ANSWER,
	SET_ALL_ANSWERS,
	SEND,
	TOGGLE_EXPANDABLE,
	EXPAND_ALL,
	PRINT_FINISHED,
	SET_TOKEN,
	SET_LANGUAGE,
} from './actions';
import { getLocal } from '../Utils';

const base = survey.languages.reduce(
	(data, nextLanguage) => {
		return nextLanguage.sections.reduce(
			(data, nextSection) => {
				nextSection.questions.forEach((q) => {
					data.questions[nextSection.id + '-' + q.id] = null;
					data.expanded[nextSection.id + '-' + q.id] = false;
				});
				return data;
			},
			{ questions: {}, expanded: {} },
		);
	},
	{ languages: [] },
);

const getBrowserLanguage = () => {
	let preferedLanguage;
	if (getLocal('language')) {
		preferedLanguage = getLocal('language');
		return preferedLanguage;
	} else {
		if (!navigator.language) {
			return { language: 'Suomi', id: 0 };
		} else {
			preferedLanguage = navigator.language.split('-')[0];
		}
	}
	switch (preferedLanguage) {
		case 'en':
			return { language: 'English', id: 2 };
		case 'sv':
			return { language: 'Svenska', id: 1 };
		default:
			return { language: 'Suomi', id: 0 };
	}
};

const initialState = {
	survey: survey,
	answers: base.questions,
	expanded: base.expanded,
	sent: false,
	readyToPrint: false,
	token: '',
	language: getBrowserLanguage(),
	languages: survey.languages.map((l, i) => {
		return { option: l.name, id: i };
	}),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ANSWER:
			let { section, questionId, value } = action.data;

			let answerUpdated = { ...state.answers };
			answerUpdated[section + '-' + questionId] = value;
			return {
				...state,
				answers: answerUpdated,
			};
		case SET_ALL_ANSWERS:
			return {
				...state,
				answers: action.data.answersJSON,
			};
		case SEND:
			return {
				...state,
				sent: true,
			};
		case TOGGLE_EXPANDABLE:
			let id = action.data.id;

			let expandedUpdated = { ...state.expanded };
			expandedUpdated[id] = !state.expanded[id];
			return {
				...state,
				expanded: expandedUpdated,
			};
		case EXPAND_ALL:
			let expandAll = { ...state.expanded };
			for (const key in expandAll) {
				if (expandAll.hasOwnProperty(key)) {
					expandAll[key] = true;
				}
			}
			return {
				...state,
				expanded: expandAll,
				readyToPrint: true,
			};
		case PRINT_FINISHED:
			return {
				...state,
				readyToPrint: false,
			};
		case SET_TOKEN:
			return {
				...state,
				token: action.data.token,
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: action.data,
			};
		default:
			return state;
	}
};

export default reducer;
