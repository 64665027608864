import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';

const BarChart = ({ content }) => {
	const ref = useRef(null);
	useEffect(() => {
		new Chart(ref.current, {
			type: 'bar',
			data: {
				labels: Object.keys(content),
				datasets: [
					{
						label: 'Vastausten määrä',
						data: Object.values(content),
						backgroundColor: [
							'rgba(235, 52, 52, 0.2)',
							'rgba(235, 110, 52, 0.2)',
							'rgba(235, 164, 52, 0.2)',
							'rgba(247, 235, 69, 0.2)',
							'rgba(215, 247, 69, 0.2)',
							'rgba(164, 247, 69, 0.2)',
							'rgba(0, 242, 8, 0.2)',
						],
						borderColor: [
							'rgba(235, 52, 52, 1)',
							'rgba(235, 110, 52, 1)',
							'rgba(235, 164, 52, 1)',
							'rgba(247, 235, 69, 1)',
							'rgba(215, 247, 69, 1)',
							'rgba(164, 247, 69, 1)',
							'rgba(0, 242, 8, 1)',
						],
						borderWidth: 1,
					},
				],
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								stepSize: 1,
							},
						},
					],
				},
				legend: {
					labels: {
						boxWidth: 0,
					},
				},
			},
		});
	}, [content]);

	return <canvas ref={ref} />;
};

export default BarChart;
