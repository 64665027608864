import styled from 'styled-components';
import { color, colorAdjust, cursor, font } from '../../../Styles';

export const ButtonText = styled.span`
	color: ${(props) => props.color};
	${font.DCBold};
	font-size: 18px;
	padding: 2px 10px 0 10px;
	text-transform: uppercase;
`;

export const CustomBtn = styled.button`
	border: none;
	width: auto;
	height: 26px;
	display: flex;
	${cursor.clickable};
	background-color: ${color.BG0};
	&:disabled {
		${cursor.notAllowed};
		& ${ButtonText} {
			color: ${(props) => colorAdjust.lighten(props.color, 0.4)};
		}
	}
	&:focus {
		border: none;
		outline: none;
	}
`;

export const ArrowIcon = styled.div`
	background: url(${(props) => props.src});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 20px;
	width: 10px;
`;
