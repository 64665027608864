import styled from 'styled-components';
import { color, font } from '../../../../Styles';

export const SummarySectionTitleDiv = styled.div`
	width: 100%;
`;

export const SummarySectionTitle = styled.h2`
	margin: 0;
	${font.title};
`;

export const SummaryDescriptionDiv = styled.div`
	width: 100%;
`;

export const SummaryQuestionDiv = styled.div`
	width: 100%;
`;

export const SummaryDescription = styled.p`
	${font.PBReg};
	font-size: 22px;
`;
