import React from 'react';
import { connect } from 'react-redux';
import { postAnswer } from '../../../services/APIservice';
import { send } from '../../../redux/actions';
import { ArrowIcon, ButtonText, CustomBtn } from './styles';
import ArrowRightBlue from '../../../assets/images/arrow_right_blue.png';
import ArrowLeftGray from '../../../assets/images/arrow_left_gray.png';
import { color } from '../../../Styles';

const CustomButton = ({ id, onClick, text, answers, send, data, disabled }) => {
	const getClickEventHandler = () => {
		switch (id) {
			case 'send':
				return clickSend();
			default:
				return console.warn('unhandled button id');
		}
	};

	const clickSend = () => {
		postAnswer(data.answerId, answers)
			.then((response) => {
				send();
			})
			.catch((response) => {
				alert(
					`Unable to send answer to server, please contact support (${response})`,
				);
			});
	};
	const isDisabled = disabled
		? true
		: id === 'send' && Object.values(answers).some((a) => a === null);

	return (
		<CustomBtn
			id={id}
			color={id === 'previous' ? color.text : color.primary}
			onClick={onClick ? onClick : getClickEventHandler}
			disabled={isDisabled}
			type="button"
		>
			{id === 'previous' && (
				<ArrowIcon
					src={id === 'previous' ? ArrowLeftGray : ArrowRightBlue}
				/>
			)}
			<ButtonText color={id === 'previous' ? color.text : color.primary}>
				{text}
			</ButtonText>
			{id !== 'previous' && <ArrowIcon src={ArrowRightBlue} />}
		</CustomBtn>
	);
};

const mapStateToProps = (state) => ({
	answers: state.answers,
});

const mapDispatchToProps = {
	send,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
