import React, { useState, useEffect } from 'react'
import Info from '../Info'

const EmailCustomization = ({ emailContent, setEmailContent, emailHeader, setEmailHeader, color, setColor, setEmailImage }) => {

    const [invalidColor, setInvalidColor] = useState(false)

    useEffect(() => {
        if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color)) {
            setInvalidColor(false)
        } else {
            setInvalidColor(true)
        }
    }, [color])

    const previewImage = () => {
        const target = document.getElementById('imagePreview')
        const file = document.getElementById('emailImage').files[0]

        const reader = new FileReader()

        reader.onloadend = () => {
            setEmailImage(reader.result)
            target.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            target.src = "";
        }
    }

    return (
        <div>
            <h3>Sähköpostikutsun kustomointi <Info field='email'/></h3>
            <span className='createSurveyLabel'>Kuva:</span><br />
            <input type='file' accept='image/x-png,image/png,image/gif,image/jpg,image/jpeg' className='createSurveyInput' id='emailImage' onChange={previewImage} /><br />
            <span className='createSurveyLabel'>Teemaväri heksadesimaalina: <Info field='color'/></span><br/>
            <input type='text' className='createSurveyInput' value={color} onChange={(e) => setColor(e.target.value)} style={{ borderColor: invalidColor ? 'red' : '', backgroundColor: invalidColor ? 'lightPink' : '' }} /><br/>
            <span className='createSurveyLabel'>Otsikko: </span><br />
            <input type='text' id='emailHeader' className='createSurveyInput' value={emailHeader} onChange={(e) => setEmailHeader(e.target.value)} /><br />
            <span className='createSurveyLabel'>Sisältö:</span><br />
            <textarea className='createSurveyInput email' value={emailContent} onChange={(e) => setEmailContent(e.target.value)} /><br />
            <span className='createSurveyLabel' id='emailPreviewHeader'>Sähköpostin esikatselu:</span><br/>
            <div style={{display: 'flex', width: '100%', backgroundColor: '#e6e6e6', paddingTop: '10px', paddingBottom: '10px'}}>
                <div id='emailPreview' style={{ backgroundColor: color, maxWidth: '600px', margin: 'auto' }}>
                    <div id='emailContent' style={{backgroundColor: '#FFFFFF', marginTop: '40px', textAlign: 'center'}}>
                        <img src='' alt='logo' id='imagePreview' style={{ width: '150px', marginTop: '40px' }}></img>
                        <h3 style={{marginLeft: '20px', marginRight: '20px'}}>{emailHeader}</h3>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{emailContent}</p>
                        {/*eslint-disable-next-line*/}
                        <a id='startButton' style={{ width: '100px', height: '30px', border: 'none', backgroundColor: color, color: 'white', marginBottom: '40px', marginTop: '30px', display: 'inline-block', paddingTop: '10px', textDecoration: 'none'}}>ALOITA!</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailCustomization