import React from 'react';
import { useHistory } from 'react-router-dom';

import DoughnutChart from '../../results/charts/DoughnutChart';
import './surveyListItem.css';
import { deleteSurvey } from './../../../services/APIservice';
import {
	DeleteSurveyButton,
	DoughnutChartContainer,
	SurveyListItemCol,
	SurveyListItemHeader,
	SurveyListItemInfo,
	SurveyListItemTable,
} from './styles';

const SurveyListItem = ({
	name,
	deadline,
	minimumAnswers,
	answerStats,
	redirect,
	remove,
	id,
}) => {
	const formattedDeadline = deadline
		.split('T')[0]
		.split('-')
		.reverse()
		.join('.');

	const enabled = answerStats.answered >= minimumAnswers;
	const history = useHistory();
	const handleClick = () => {
		if (enabled) {
			history.push(`/results/${id}`);
		}
	};

	const removeClicked = (event) => {
		event.stopPropagation();
		if (window.confirm(`Haluatko varmasti poistaa kyselyn "${name}"`)) {
			if (
				window.confirm(
					`Toimintoa EI voi peruuttaa, kaikki kyselyyn "${name}" liittyvä data poistetaan. Oletko varma?`,
				)
			) {
				deleteSurvey(id)
					.then((response) => {
						remove(id);
					})
					.catch((error) => {
						console.log(error.response);
						if (error.response.status === 401) {
							remove(id);
						} else {
							alert(error.response.statusText);
						}
					});
			}
		}
	};

	return (
		<SurveyListItemCol onClick={handleClick} enabled={enabled}>
			<div style={{ position: 'relative' }}>
				<DeleteSurveyButton onClick={(e) => removeClicked(e)}>
					X
				</DeleteSurveyButton>
			</div>
			<SurveyListItemTable>
				<thead>
					<tr>
						<td colSpan={2}>
							<SurveyListItemHeader>{name}</SurveyListItemHeader>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<DoughnutChartContainer rowSpan={2}>
							<DoughnutChart
								answered={answerStats.answered}
								minimum={minimumAnswers}
								notAnswered={answerStats.notAnswered}
							/>
						</DoughnutChartContainer>
						<SurveyListItemInfo>
							<strong>Vastauksia: </strong>
							{answerStats.answered}/
							{answerStats.answered + answerStats.notAnswered}
							{answerStats.answered < minimumAnswers ? (
								<>
									<br />
									(tuloksiin tarvitaan {minimumAnswers})
								</>
							) : (
								''
							)}
						</SurveyListItemInfo>
					</tr>
					<tr>
						<SurveyListItemInfo>
							<strong>Kysely sulkeutuu:</strong>{' '}
							{formattedDeadline}
						</SurveyListItemInfo>
					</tr>
				</tbody>
			</SurveyListItemTable>
		</SurveyListItemCol>
	);
};

export default SurveyListItem;
