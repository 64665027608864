import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setToken } from '../../../redux/actions';
import CustomButton from '../../survey/CustomButton';
import { signup } from '../../../services/APIservice';
import { Redirect } from 'react-router-dom';
import { AppContainer, AppDiv } from '../../../Styles';
import SurveyCard from '../../shared/SurveyCard';
import {
	AnimatedLoginInput,
	ButtonRow,
	LoginButton,
	LoginContentContainer,
	LoginHeader,
	SignUpButton,
} from '../Login/styles';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';

const Signup = ({ token, setToken }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [verifyPassword, setVerifyPassword] = useState('');
	const [done, setDone] = useState(Boolean(token));
	const history = useHistory();

	const submit = () => {
		signup(email, password)
			.then((response) => {
				window.localStorage.setItem('token', response.data.token);
				setToken({ token: response.data.token });
				setDone(true);
			})
			.catch((error) => {
				alert(error.response.data);
			});
	};

	return done ? (
		<Redirect to="/" />
	) : (
		<SurveyCard>
			<LoginContentContainer>
				<LoginHeader>Luo käyttäjä</LoginHeader>
				<form autoComplete={'off'}>
					<AnimatedLoginInput>
						<input
							type="text"
							name={'email'}
							autoComplete={'off'}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label htmlFor={'email'}>
							<span>Sähköposti</span>
						</label>
					</AnimatedLoginInput>
					<br></br>
					<AnimatedLoginInput>
						<input
							type="password"
							name={'password'}
							autoComplete={'new-password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<label htmlFor={'password'}>
							<span>Salasana</span>
						</label>
					</AnimatedLoginInput>

					<br></br>
					<AnimatedLoginInput>
						<input
							type="password"
							name={'confirm-password'}
							autoComplete={'new-password'}
							value={verifyPassword}
							onChange={(e) => setVerifyPassword(e.target.value)}
						/>
						<label htmlFor={'confirm-password'}>
							<span>Vahvista salasana</span>
						</label>
					</AnimatedLoginInput>
				</form>
				<br></br>
				<ButtonRow />
				<LoginButton
					id="login"
					onClick={submit}
					disabled={password !== verifyPassword || password === ''}
				>
					Kirjaudu
				</LoginButton>
				<SignUpButton onClick={() => history.push('/')}>
					<Link to="/">Takaisin</Link>
				</SignUpButton>
			</LoginContentContainer>
		</SurveyCard>
	);
};

const mapStateToProps = (state) => ({
	token: state.token,
});

const mapDispatchToProps = {
	setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
