import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SurveyListItem from '../SurveyListItem';
import { Redirect } from 'react-router-dom';
import { getSurveys } from '../../../services/APIservice';
import { setToken } from '../../../redux/actions';
import CustomButton from '../../survey/CustomButton';
import {
	ButtonRow,
	LandingPageHeader,
	NewSurveyItem,
	NewSurveyItemLabel,
	SurveyListRow,
	SurveysHeader,
} from './styles';
import SurveyCard from '../../shared/SurveyCard';
import Footer from '../../shared/Footer';
import { SummaryHeader } from '../../results/AnswerSummary/styles';
import { AppContainer, AppDiv } from '../../../Styles';
import DropDownComponent from "../../shared/DropDown";

const LandingPage = ({ setToken, language }) => {
	const [surveys, setSurveys] = useState([]);
	const [loading, setLoading] = useState(false);
	const [redirect, setRedirect] = useState('');
	const history = useHistory();

	useEffect(() => {
		getSurveys()
			.then((response) => {
				setSurveys(response.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(
					`Something went wrong, please contact support (${error.response.data})`,
				);
			});
	}, []);

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	const remove = (id) => {
		setSurveys(surveys.filter((survey) => survey.id !== id));
	};

	const surveyListItems = [];

	const newListItem = (
		<NewSurveyItem onClick={() => history.push('/survey/new')}>
			<NewSurveyItemLabel>
				<strong>Luo uusi kysely</strong>
			</NewSurveyItemLabel>
		</NewSurveyItem>
	);

	let newListItemLinkAdded = false;
	const renderSurveys = () => {
		return surveys.map((survey) => (
			<SurveyListItem
				key={survey.id}
				name={survey.name}
				deadline={survey.deadline}
				minimumAnswers={survey.minimumAnswers}
				answerStats={survey.answerStats}
				redirect={setRedirect}
				remove={remove}
				id={survey.id}
			/>
		));
	};

	for (let i = 0; i < surveys.length; i = i + 2) {
		const left = surveys[i];
		const right = surveys[i + 1];

		if (right) {
			surveyListItems.push(
				<SurveyListRow key={`row-${left.id}-${right.id}`}>
					<SurveyListItem
						key={left.id}
						name={left.name}
						deadline={left.deadline}
						minimumAnswers={left.minimumAnswers}
						answerStats={left.answerStats}
						redirect={setRedirect}
						remove={remove}
						id={left.id}
					/>
					<SurveyListItem
						key={right.id}
						name={right.name}
						deadline={right.deadline}
						minimumAnswers={right.minimumAnswers}
						answerStats={right.answerStats}
						redirect={setRedirect}
						remove={remove}
						id={right.id}
					/>
				</SurveyListRow>,
			);
		} else {
			surveyListItems.push(
				<SurveyListRow key={`row-${left.id}`}>
					<SurveyListItem
						key={left.id}
						name={left.name}
						deadline={left.deadline}
						minimumAnswers={left.minimumAnswers}
						answerStats={left.answerStats}
						redirect={setRedirect}
						remove={remove}
						id={left.id}
					/>
					{newListItem}
				</SurveyListRow>,
			);
			newListItemLinkAdded = true;
		}
	}

	if (!newListItemLinkAdded) {
		surveyListItems.push(
			<div
				key="new"
				className="surveyListItemRow"
				style={{ marginTop: '5px' }}
			>
				{newListItem}
			</div>,
		);
	}

	const logout = () => {
		window.localStorage.removeItem('token');
		setToken({ token: '' });
	};


	return loading ? (
		''
	) : (
		<SurveyCard>
			<LandingPageHeader>Tervetuloa MOTI:in!</LandingPageHeader>
			<SurveysHeader>Kyselyt</SurveysHeader>
			<SurveyListRow>{renderSurveys()}</SurveyListRow>
			<SurveyListRow>{newListItem}</SurveyListRow>
			<ButtonRow>
				<CustomButton
					id="logoutButton"
					onClick={logout}
					text="Kirjaudu ulos"
				/>
			</ButtonRow>
		</SurveyCard>
	);
};

const mapDispatchToProps = {
	setToken,
};



export default connect(null, mapDispatchToProps)(LandingPage);
