import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { getAnswer, getAverages } from '../../../services/APIservice';
import DeviationLine from '../DeviationLine';
import ExpandableContent from '../ExpandableContent';
import PDFDownloader from '../PDFDownloader';
import {
	SummaryContainerDiv,
	SummaryFeedDiv,
	SummaryHeader,
	SummaryHeaderText,
} from './styles';
import SurveyCard from '../../shared/SurveyCard';
import AnswerSummaryCard from './AnswerSummaryCard';
import { expandAll, setAllAnswers } from '../../../redux/actions';

const AnswererSummary = ({
	surveyContents,
	answers,
	setAllAnswers,
	expandAll,
	language,
}) => {
	const [averages, setAverages] = useState(undefined);
	const SummaryWindow = useRef();
	const answerId = useParams().answerId;
	const [surveyFound, setSurveyFound] = useState('loading');

	useEffect(() => {
		getAverages('answerId', answerId)
			.then((response) => {
				setAverages(response);
			})
			.catch((error) => {
				alert('Could not fetch averages from the server');
			});
		getAnswer(answerId)
			.then((response) => {
				console.log(response.data);
				setSurveyFound(response.data);
			})
			.catch((error) => {
				setSurveyFound('404');
			});
	}, [answerId]);

	const feedbackTitleLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Tack för ditt svar!';
			case 'Suomi':
				return 'Kiitos vastauksesta!';

			default:
				return 'Thank you for your response!';
		}
	};

	const feedbackParagraphLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Bekanta dig med responsen enkäten ger dig och skriv ut den i pdf-format om du vill. Utnyttja responsen och de följdfrågor responsen ger dig när du förbereder dig för ditt utvecklingssamtal med din förman.';
			case 'Suomi':
				return `Tutustu kyselyn sinulle antamaan palautteeseen ja tulosta se halutessasi pdf-muodossa. Hyödynnä palautetta ja palautteessa esitettyjä jatkokysymyksiä valmistautuessasi kehityskeskusteluun esimiehesi kanssa.`;

			default:
				return `Get acquainted with the response from the survey and feel free to print it out as a PDF. Take advantage of the response and the follow-up questions it provides you with when preparing for your performance appraisal with your supervisor.`;
		}
	};

	if (Object.values(answers).some((a) => a === null)) {
		if (surveyFound === 'loading') {
			return <p>loading</p>;
		} else if (surveyFound === '404') {
			return <Redirect to={'/404'} />;
		} else if (surveyFound !== '') {
			setAllAnswers({ answersJSON: surveyFound });
		}
	}

	const renderSections = () => {
		if (averages) {
			return surveyContents.languages[language.id].sections.map(
				(section, index) => {
					return (
						<AnswerSummaryCard
							key={index}
							answers={answers}
							averages={averages}
							section={section}
						/>
					);
				},
			);
		}
	};

	return (
		<>
			<SurveyCard boxShadow={false}>
				<SummaryHeader>{feedbackTitleLanguage()}</SummaryHeader>
				<SummaryHeaderText>
					{feedbackParagraphLanguage()}
				</SummaryHeaderText>
			</SurveyCard>
			<div ref={SummaryWindow} id={'to-print'} style={{ width: '100%' }}>
				{renderSections()}
			</div>
			<SurveyCard boxShadow={false}>
				{!!SummaryWindow && <PDFDownloader printRef={SummaryWindow} />}
			</SurveyCard>
		</>
	);
};

const mapStateToProps = (state) => ({
	surveyContents: state.survey,
	answers: state.answers,
	language: state.language,
});

const mapDispatchToProps = {
	setAllAnswers,
	expandAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswererSummary);
