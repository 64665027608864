import React from 'react';
import Likert from '../Likert';
import {
	SurveyContainer,
	SurveyDescription,
	SurveyDescriptionDiv,
	SurveyLogoImg,
	SurveySubtitle,
	SurveySubtitleDiv,
	SurveyTitle,
	SurveyTitleDiv,
} from './styles';
import MOTI_logo from '../../../assets/images/COF_Moti_Logo.svg';
import DropDownComponent from '../Survey';
import { NavigationRowDiv } from '../Survey/styles';

const Section = ({ data, active, customStyles }) => {
	const questions = data.questions.map((q) => {
		switch (q.type) {
			case 'likert':
				return (
					<Likert
						key={q.id}
						question={q}
						section={data.id}
						customStyles={customStyles}
						id={q.id}
					/>
				);
			default:
				console.error('incorrect question type');
				return '';
		}
	});
	return (
		<SurveyContainer hidden={!active}>
			<SurveyTitleDiv>
				<SurveyTitle>{data.title}</SurveyTitle>
				<SurveyLogoImg src={MOTI_logo} />
			</SurveyTitleDiv>
			<SurveySubtitleDiv>
				<SurveySubtitle>
					<i>
						{data.subtitle.match(/^".*"/g)
							? data.subtitle
							: `"${data.subtitle}"`}
					</i>
				</SurveySubtitle>
			</SurveySubtitleDiv>
			<SurveyDescriptionDiv>
				<SurveyDescription>{data.description}</SurveyDescription>
			</SurveyDescriptionDiv>
			{questions}
		</SurveyContainer>
	);
};

export default Section;
