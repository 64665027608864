import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js';

const LineChart = ({ content }) => {
	const ref = useRef(null);
	const [current, setCurrent] = useState(undefined);
	const [prevContent, setPrevContent] = useState(undefined);

	useEffect(() => {
		if (prevContent === content) {
			return;
		}

		if (current) {
			current.destroy();
		}

		const colors = [
			'rgba(3, 99, 255, 0.5)',
			'rgba(235, 52, 52, 0.5)',
			'rgba(235, 110, 52, 0.5)',
			'rgba(235, 164, 52, 0.5)',
			'rgba(247, 235, 69, 0.5)',
			'rgba(215, 247, 69, 0.5)',
			'rgba(164, 247, 69, 0.5)',
			'rgba(0, 242, 8, 0.5)',
		];
		let datasets = Object.keys(content).map((key, i) => ({
			label: key,
			data: Object.values(content[key]),
			fill: false,
			pointRadius: 5,
			lineTension: 0.2,
			pointBackgroundColor: colors[i % 7],
			borderColor: colors[i],
		}));

		const c = new Chart(ref.current, {
			type: 'line',
			data: {
				labels: Object.keys(content.all),
				datasets,
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								stepSize: 1,
								suggestedMax: 7,
							},
						},
					],
				},
			},
		});

		setCurrent(c);
		setPrevContent(content);
	}, [content, current, prevContent]);

	return <canvas ref={ref} />;
};

export default LineChart;
