import styled from 'styled-components';
import { components, units } from '../../../Styles';

export const DownloadPDFButton = styled.button`
	${components.buttonWhite};
	font-size: 20px;
	height: 36px;
	line-height: 26px;
	padding: 5px 12px 5px 12px;
	@media (max-width: ${units.tablet}) {
		font-size: calc(8px + 2vw);
	}
`;
