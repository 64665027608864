import React from 'react';
import { SurveyCardContainer, SurveyCardDiv } from './styles';

const SurveyCard = ({ children, boxShadow = true }) => {
	return (
		<SurveyCardDiv boxShadow={boxShadow}>
			<SurveyCardContainer>{children}</SurveyCardContainer>
		</SurveyCardDiv>
	);
};

export default SurveyCard;
