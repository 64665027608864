import styled from 'styled-components';
import { color, font, units } from '../../../Styles';

export const FooterDiv = styled.div`
	width: 100%;
	padding: 20px 0;
	display: flex;
`;

export const LegalDiv = styled.div`
	display: flex;
	margin-right: auto;
`;

export const LegalLink = styled.a`
	${font.PBReg};
	color: ${color.BG2};
	font-size: 18px;
	line-height: 18px;
	margin-right: 10px;
	text-decoration: none;
	&:visited {
		color: ${color.BG2};
	}
	@media (max-width: ${units.tablet}) {
		font-size: calc(2px + 2vw);
	}
`;

export const CoFName = styled.span`
	${font.PBReg};
	color: ${color.BG2};
	font-size: 18px;
	line-height: 18px;
	margin-left: auto;
	@media (max-width: ${units.tablet}) {
		font-size: calc(2px + 2vw);
	}
`;

export const CoFLogoImg = styled.img`
	height: 13px;
	margin-left: 6px;
	@media (max-width: ${units.tablet}) {
		font-size: calc(2px + 2vw);
	}
`;
