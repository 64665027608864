import React from 'react';
import { connect } from 'react-redux';

import {
	AnsweredOption,
	AverageOption,
	CustomLineChartContainer,
	CustomLineChartOptionDiv,
	CustomLineChartStrikeThrough,
	MobileStatSummary,
	OptionDiv,
	OptionDivContainer,
	OptionLabel,
	OptionTitle,
} from './styles';
import { setLanguage } from '../../../../../redux/actions';

const CustomLineChart = ({ average, answer, question, language }) => {
	const yourAnswerLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Ditt svar';
			case 'Suomi':
				return 'Vastauksesi';

			default:
				return 'Your answer';
		}
	};

	const averageLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Medeltalet';
			case 'Suomi':
				return 'Keskiarvo';

			default:
				return 'The average';
		}
	};

	const renderOptions = () =>
		[...Array(7).keys()].map((option) => {
			if (answer === option + 1)
				return (
					<AnsweredOption key={option}>
						<OptionLabel>{option + 1}</OptionLabel>
						<OptionTitle average={false}>
							{yourAnswerLanguage()}
						</OptionTitle>
					</AnsweredOption>
				);
			else if (Math.round(average) === option + 1)
				return (
					<AverageOption key={option}>
						<OptionLabel>{option + 1}</OptionLabel>
						<OptionTitle average={true}>
							{averageLanguage()}
						</OptionTitle>
					</AverageOption>
				);
			else {
				return (
					<OptionDivContainer key={option}>
						<OptionDiv />
					</OptionDivContainer>
				);
			}
		});
	return (
		<CustomLineChartContainer>
			<br />
			<MobileStatSummary>{`${yourAnswerLanguage()}: ${answer}`}</MobileStatSummary>
			<MobileStatSummary>
				{`${averageLanguage()}: ${average}`}
			</MobileStatSummary>

			<CustomLineChartStrikeThrough />
			<CustomLineChartOptionDiv>
				{renderOptions()}
			</CustomLineChartOptionDiv>
		</CustomLineChartContainer>
	);
};

const mapDispatchToProps = {
	setLanguage,
};

const mapStateToProps = (state) => ({
	language: state.language,
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomLineChart);
