import styled from 'styled-components';
import { color, cursor, font, units } from '../../../Styles';

export const SurveyResultsDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	background-color: ${color.BG1};
`;

export const SurveyResultsContainer = styled.div`
	width: 80%;
	background-color: ${color.BG1};
	margin: 5% 10% auto 10%;
	display: flex;
	flex-direction: column;
	@media (max-width: ${units.mobile}) {
		width: 90%;
		margin: 5% 5% auto 5%;
	}
`;

export const ResultsTitle = styled.h1`
	${font.title};
`;

export const ResultsContainer = styled.div`
	width: 100%;
	display: flex;
	border: 2px solid ${color.primary};
	flex-direction: column;
	border-radius: 4px;
`;

export const ResultsNavTab = styled.div`
	width: 50%;
	height: 30px;
	display: flex;
	${cursor.clickable};
	color: ${(props) => (props.selected ? color.BG0 : color.primary)};
	transition: background-color 0.1s ease-in-out;
	background-color: ${(props) =>
		props.selected ? color.primary : color.BG0};
	&:hover {
		background-color: ${(props) =>
			!props.selected ? color.BG1 : color.primaryShade};
		color: ${(props) => (props.selected ? color.BG0 : color.primary)};
	}
`;

export const ResultsNavigation = styled.div`
	width: 100%;
	display: flex;

	border-bottom: 2px solid ${color.primary};

	& ${ResultsNavTab} {
		border-right: 2px solid ${color.primary};
	}
	& ${ResultsNavTab}:last-child {
		border-right: none;
	}
`;

export const ResultsNavTabTitle = styled.span`
	margin: auto;
	${font.DCBold};
	padding-top: 4px;
	font-size: 22px;
`;

export const ResultsStatsWindow = styled.div`
	width: 90%;
	padding: 5%;
`;
