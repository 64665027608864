import React, {
	createRef,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import cookie from 'cookie';


import { useHistory } from 'react-router';
import { useLayoutEffect } from 'react';
import { getLocal, setLocal } from '../Utils';
import { throttle } from 'lodash';


// custom hook for easy modal dismissal
export const useDismiss = (
	refInside,
	close,
	exclude,
) => {
	const handleEsc = (e) => {
		if (e.key !== 'Escape') return;
		else {
			e.preventDefault();
			// esc by default stops the page from refreshing,
			// this is not a problem but causes a small delay when pressing.
			close();
		}
	};
	const handleClick = (e) => {
		let target = e.target;
		if (
			refInside?.current?.contains(target) ||
			exclude?.current?.contains(target)
		)
			return;
		else close();
	};
	useEffect(() => {
		document.addEventListener('keydown', handleEsc);
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener(
				'keydown',
				handleEsc,
			);
			document.removeEventListener(
				'mousedown',
				handleClick,
			);
		};
	}, []);
};

// A hook that helps with checking if a component is mounted,
// used to check if a component is still mounted before updating a state
export const useMounted = () => {
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);
	return isMounted;
};