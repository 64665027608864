import React from 'react';
import { connect } from 'react-redux';
import CoFLogo from '../../../assets/images/cof-logo-gray.png';
import { CoFLogoImg, CoFName, FooterDiv, LegalDiv, LegalLink } from './styles';
import ToSFin from '../../../assets/files/moti_kayttoehdot.pdf';
import PrivacyStatementFin from '../../../assets/files/moti_tietosuojaseloste.pdf';
import ToSEng from '../../../assets/files/co-founders-tos-eng.pdf';
import PrivacyStatementEng from '../../../assets/files/co-founders-privacy-statement-eng.704497ce.pdf';
const Footer = ({ language }) => {
	const tosLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Användarvillkor';
			case 'Suomi':
				return 'Käyttöehdot';

			default:
				return 'Terms of service';
		}
	};

	const privacyStatementLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Sekretesspolicy';
			case 'Suomi':
				return 'Tietoturvaseloste';

			default:
				return 'Privacy statement';
		}
	};

	return (
		<FooterDiv>
			<LegalDiv>
				<LegalLink
					target={'_blank'}
					href={language.language === 'Suomi' ? ToSFin : ToSEng}
				>
					{tosLanguage()}
				</LegalLink>
				<LegalLink
					target={'_blank'}
					href={
						language.language === 'Suomi'
							? PrivacyStatementFin
							: PrivacyStatementEng
					}
				>
					{privacyStatementLanguage()}
				</LegalLink>
			</LegalDiv>
			<CoFName>{'© Created by '}</CoFName>
			<CoFLogoImg src={CoFLogo} />
		</FooterDiv>
	);
};

const mapStateToProps = (state) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Footer);
