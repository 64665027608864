import styled, { css } from 'styled-components';
import { color, cursor, font, units } from '../../../../../Styles';
import { animated } from 'react-spring';

export const FeedbackDiv = styled.div`
	width: 100%;
	margin-top: 50px;
`;

export const ExpandFeedBackDiv = styled.div`
	width: 100%;
	display: flex;
	border-top: 1px solid ${color.BG2};
	border-bottom: 1px solid ${color.BG2};
	& p {
		${font.DCBold};
		font-size: 20px;
		padding-top: 6px;
		color: ${color.primary};
	}
`;

export const ArrowIcon = styled.div`
	background: url(${(props) => props.src});
	transform: ${(props) =>
		props.expanded ? 'rotate(90deg)' : 'rotate(0deg)'};
	background-size: contain;
	transition: all 0.1s ease-in-out;
	background-position: center;
	margin: auto 10px;
	background-repeat: no-repeat;
	height: 22px;
	width: 20px;
`;

export const FeedBackContent = styled(animated.div)`
	width: 100%;
	font-size: 18px;
	line-height: 24px;
	${font.PBReg};
	overflow-y: hidden;
	& h2 {
		padding-top: 1em;
		margin: 0;
		${font.DCBold};
	}
	& ul {
		padding: 0 1em;
		margin-left: 1em;
	}
	& li {
		padding: 1em 1em;
	}
`;
