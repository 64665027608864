import React from 'react';
import { connect } from 'react-redux';
import { setAnswer } from '../../../redux/actions';
import './customRadioButton.css';
import { Label, LabelTitle, Radio, RadioButtonContainer } from './styles';

const CustomRadioButton = ({ value, group, questionId, setAnswer }) => {
	return (
		<RadioButtonContainer className={'radio-option'}>
			<Radio htmlFor={group + '-' + questionId + '-' + value}>
				<input
					type="radio"
					name={group + '-' + questionId}
					id={group + '-' + questionId + '-' + value}
					className="hidden"
					onChange={() =>
						setAnswer({ section: group, questionId, value })
					}
				/>
				<Label />
				<br></br>
				<LabelTitle>{value}</LabelTitle>
			</Radio>
		</RadioButtonContainer>
	);
};

const mapDispatchToProps = {
	setAnswer,
};

export default connect(null, mapDispatchToProps)(CustomRadioButton);
