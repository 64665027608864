import React from 'react';
import {
	SummaryDescription,
	SummaryDescriptionDiv,
	SummaryQuestionDiv,
	SummarySectionTitle,
	SummarySectionTitleDiv,
} from './styles';
import SurveyCard from '../../../shared/SurveyCard';
import { SurveyDescription } from '../../../survey/Section/styles';
import { connect } from 'react-redux';
import CustomLineChart from './CustomLineChart';
import ExpandableFeedback from './ExpandableFeedback';

const AnswerSummaryCard = ({ section, answers, averages }) => {
	const renderSectionQuestions = (section) => {
		return section.questions.map((question, index) => {
			const answerKey = section.id + '-' + question.id;

			const answer = answers[answerKey];
			const average = averages[answerKey];
			return (
				<SummaryQuestionDiv key={index}>
					<SummaryDescriptionDiv>
						<SummaryDescription>
							{question.question}
						</SummaryDescription>
					</SummaryDescriptionDiv>
					<CustomLineChart average={average} answer={answer} />
					<ExpandableFeedback
						id={answerKey}
						answer={answer}
						question={question}
					/>
				</SummaryQuestionDiv>
			);
		});
	};

	return (
		<SurveyCard boxShadow={false}>
			<SummarySectionTitleDiv>
				<SummarySectionTitle>{section.title}</SummarySectionTitle>
			</SummarySectionTitleDiv>
			{renderSectionQuestions(section)}
		</SurveyCard>
	);
};

export default AnswerSummaryCard;
