import styled from 'styled-components';
import { color, cursor, font, units } from '../../../Styles';

export const MotiLogoDiv = styled.div`
	width: 100%;
	display: flex;
`;

export const MotiLogo = styled.img`
	width: 50%;
	max-height: 200px;
	max-width: 30vh;
	margin: 0 auto;
`;

export const MotiWelcomeVideoContainer = styled.div`
	width: 90%;
	max-width: 60vh;
	min-width: 200px;
	margin: 20px auto;
`;

export const MotiWelcomeVideo = styled.video`
	position: relative;
	z-index: 1;
	&:focus {
		outline: none;
	}
`;

export const WelcomeTitleDiv = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	text-align: center;
`;

export const WelcomeTitle = styled.h1`
	font-size: 40px;
	margin: 0 auto;
	width: 100%;
	word-break: break-word;
	color: ${color.primary};
	${font.PBReg};
	font-weight: lighter;
	@media (max-width: ${units.tablet}) {
		font-size: calc(16px + 2vw);
	}
`;

export const WelcomeTextDiv = styled.div`
	display: flex;
	width: 100%;
	text-align: center;
`;

export const WelcomeText = styled.p`
	${font.PBReg};
	width: 70%;
	text-align: center;
	margin: 1em auto 2em auto;
	font-weight: lighter;
`;

export const ButtonDiv = styled.div`
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: center;
`;

export const StartSurveyButton = styled.button`
	background-color: ${color.BG0};
	width: 180px;
	margin: auto;
	display: flex;
	${cursor.clickable};
	padding: 4px 10px;
	border-radius: 4px;
	border: 2px solid ${color.primary};
`;

export const StartSurveyButtonSpan = styled.span`
	color: ${color.primary};
	font-size: 20px;
	margin: auto;
	padding-top: 7px;

	${font.DCBold};
	text-transform: uppercase;
`;

export const ButtonArrow = styled.img`
	height: 20px;
	margin: auto 0;
`;
