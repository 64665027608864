import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSurveyNameAndFilters } from '../../../services/APIservice';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import Distributions from '../Distributions';
import Averages from '../Averages';
import {
	ResultsContainer,
	ResultsNavigation,
	ResultsNavTab,
	ResultsNavTabTitle,
	ResultsStatsWindow,
	ResultsTitle,
	SurveyResultsContainer,
	SurveyResultsDiv,
} from './styles';
import SurveyCard from '../../shared/SurveyCard';
import CustomButton from '../../survey/CustomButton';
import { AppContainer, AppDiv, color } from '../../../Styles';
import Footer from '../../shared/Footer';
import Median from '../Median';

const SurveyResults = ({ surveyContent }) => {
	const [surveyName, setSurveyName] = useState('');
	const [filters, setFilters] = useState([]);
	const [error, setError] = useState(undefined);
	const [selected, setSelected] = useState('averages');
	const history = useHistory();
	const surveyId = useParams().surveyId;

	useEffect(() => {
		getSurveyNameAndFilters(surveyId)
			.then((response) => {
				setSurveyName(response.data.name);
				setFilters(response.data.filters);
			})
			.catch((error) => {
				console.log(error.response);
				setError(error.response);
			});
	}, [surveyId]);

	if (error?.status === 403 || error?.status === 500) {
		return (
			<div className="container">
				<h3>{error.data.error}</h3>
			</div>
		);
	} else if (error?.status === 401) {
		return <Redirect to="/login" />;
	} else if (error?.status === 404) {
		return <Redirect to="/404" />;
	}
	const handleClick = (value) => {
		setSelected(value);
	};

	const renderContent = () => {
		switch (selected) {
			case 'distributions':
				return (
					<Distributions
						surveyId={surveyId}
						surveyContent={surveyContent}
						setErrorMessage={setError}
					/>
				);
			case 'mediaani':
				return (
					<Median
						surveyId={surveyId}
						filters={filters}
						setErrorMessage={setError}
					/>
				);
			default:
				return (
					<Averages
						surveyId={surveyId}
						filters={filters}
						setErrorMessage={setError}
					/>
				);
		}
	};

	const style = {
		display: 'inline-block',
		padding: '5px',
		cursor: 'pointer',
	};

	const styleForSelected = {
		...style,
		textDecoration: 'underline',
		borderRadius: '5px',
		color: color.BG0,
		backgroundColor: color.primary,
	};

	return (
		<SurveyCard>
			<CustomButton
				id={'previous'}
				text={'Takaisin'}
				onClick={() => history.push('/')}
			/>

			<ResultsTitle>{surveyName} -kyselyn tulokset</ResultsTitle>
			<ResultsContainer>
				<ResultsNavigation>
					<ResultsNavTab
						selected={selected === 'averages'}
						onClick={() => handleClick('averages')}
					>
						<ResultsNavTabTitle>Keskiarvot</ResultsNavTabTitle>
					</ResultsNavTab>
					<ResultsNavTab
						selected={selected === 'distributions'}
						onClick={() => handleClick('distributions')}
					>
						<ResultsNavTabTitle>Jakaumat</ResultsNavTabTitle>
					</ResultsNavTab>
					<ResultsNavTab
						selected={selected === 'mediaani'}
						onClick={() => handleClick('mediaani')}
					>
						<ResultsNavTabTitle>Mediaani</ResultsNavTabTitle>
					</ResultsNavTab>
				</ResultsNavigation>
				<ResultsStatsWindow>{renderContent()}</ResultsStatsWindow>
			</ResultsContainer>
		</SurveyCard>
	);
};

const mapStateToProps = (state) => ({
	surveyContent: state.survey,
});

export default connect(mapStateToProps)(SurveyResults);
