import styled from 'styled-components';

import {
	colorAdjust,
	components,
	cursor,
	font,
	color,
	units,
} from '../../../Styles';

export const DropDown = styled.div`
	position: relative;
	${font.DCBold};
	color: ${color.primary};
	font-size: 20px;
	background-color: ${color.tertiary};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	&:hover {
		background-color: ${color.tertiary};
	}
`;

export const LanguageIcon = styled.img`
	margin: auto 10px auto 0;
	height: calc(18px + ${(props) => props.height} * 0.15);
`;

export const DropDownArrow = styled.img`
	margin: auto 0 auto auto;
	transform: rotate(90deg);
	height: calc(14px + ${(props) => props.height} * 0.15);
`;

export const CurrentOption = styled.div`
	padding: 0 10px;
	height: ${(props) => props.height};
	font-size: 20px;
	border: 2px solid ${color.primary};
	border-radius: 4px;
	${cursor.clickable};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	background-color: ${color.BG0};
	text-transform: uppercase;
	display: flex;
	text-align: center;
	& span {
		vertical-align: middle;
		line-height: ${(props) => props.height};
		margin: auto 0;
		padding-top: 4px;
	}
`;

export const DropDownList = styled.div`
	position: ${(props) => (props.modalOverflow ? 'fixed' : 'absolute')};
	width: calc(${(props) => props.width} - 4px);
	background-color: ${color.BG0};
	z-index: 5;
	max-height: 300px;
	margin-top: 5px;
	overflow-y: auto;
	overflow-x: hidden;
	border: 2px solid ${color.primary};
	border-radius: 4px;
`;

export const SearchInput = styled.input`
	${components.input};
	width: calc(100% - 16px);
	margin: 2px 2px;
	text-transform: uppercase;
`;

export const Option = styled.div`
	${font.text};
	font-size: 16px;
	letter-spacing: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	height: 34px;
	width: calc(100% - 8px);
	line-height: 34px;
	margin: 0 auto;
	padding: 0 4px;
	text-align: left;
	background-color: ${color.BG0};
	transition: background-color 0.05s;
	${cursor.clickable};
	&:hover {
		background-color: ${color.tertiary};
	}
`;
