import React, { useState, useRef, useEffect } from 'react';

const Info = ({ field }) => {
	const [visible, setVisible] = useState(false);
	const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
	const [shuoldUpdate, setShouldUpdate] = useState(true);
	const ref = useRef(null);

	window.onresize = () => {
		setShouldUpdate(true);
	};

	window.onscroll = () => {
		setShouldUpdate(true);
	};

	useEffect(() => {
		const c = ref?.current?.getBoundingClientRect();

		const xCoordinate =
			c.right + 10 + 250 < window.screen.availWidth
				? c.right + 5
				: window.screen.availWidth - 300;

		setCoordinates({
			x: xCoordinate,
			y: c.top + 9,
		});
		setShouldUpdate(false);
	}, [ref, shuoldUpdate]);

	const toggleVisibility = () => {
		setShouldUpdate(true);
		setVisible(!visible);
	};

	const content = visible ? (
		<div className="dimBackground">
			<div
				className="infoContent"
				style={{ left: coordinates.x, top: coordinates.y }}
			>
				{contents[field]}
			</div>
		</div>
	) : (
		''
	);

	return (
		<div className="info" onClick={toggleVisibility} ref={ref}>
			?{content}
		</div>
	);
};

const contents = {
	minimumAnswers: (
		<p>
			Voit määritellä tässä minimimäärän vastaajia, jotka tarvitaan, jotta
			kyselyn tuloksia voidaan tarkastella. Mitä suurempi minimimäärä on,
			sitä varmempi anonymiteetti kyselyllä on.
		</p>
	),
	csv: (
		<>
			<p>
				Tämä kyselypohja tarvitsee CSV muotoisen taulukon. CSV-tiedoston
				voi luoda tallentamalla Excelillä tai Google Sheets:illä tehdyn
				taulukon CSV-muodossa.
			</p>
			<p>
				Taulukossa tulee olla vähintään yksi kenttä, jossa on
				osallistujien sähköpostiosoitteet. Muita mahdollisia kenttiä
				voidaan luoda, jos halutaan vertailla esimerkiksi eri
				yrityksissä tai rooleissa työskenteleviä vastaajia erikseen.
			</p>
			<p>Esimerkki taulukko:</p>
			<img
				src="/images/exampleCSV.png"
				alt="Esimerkki taulukko"
				id="exampleCSVimage"
			></img>
		</>
	),
	emailField: (
		<p>
			Valitse pudotuslistasta se taulukon kenttä, jossa ovat ne
			sähköpostiosoitteet, joihin tämä kysely tulee lähettää.
		</p>
	),
	additionalAttributes: (
		<>
			<p>
				Valitse tässä ne taulukon kentät, joiden mukaan haluat tuloksia
				vertailla. Voit valita niin monta kenttää kuin haluat.
			</p>
			<p style={{ fontWeight: 'bold' }}>
				HUOM! Et voi muuttaa valintaa kyselyn lähettämisen jälkeen.
			</p>
		</>
	),
	email: (
		<p>
			Tämä teksti lähtee kyselyyn osallistujien sähköposteihin. Voit
			muokata viestin sisältöä, liittää yrityksesi logon ja valita
			teemavärin mielesi mukaan.
		</p>
	),
	color: (
		<p>
			Valitse mieleisesi väri hexadesimaali-muodossa (esim. #ffffff). Voit
			valita värin mm. täältä:{' '}
			<a
				href="https://www.w3schools.com/colors/colors_picker.asp"
				target="_blank"
				rel="noopener noreferrer"
			>
				Color picker
			</a>
			. Kopioi värin hexadesimaaliarvo tähän kenttään.
		</p>
	),
};

export default Info;
