import styled, { css } from 'styled-components';
import { color, units } from '../../../Styles';

export const SurveyCardDiv = styled.div`
	width: 100%;
	display: flex;
	margin: 10px 0;
	border-radius: 4px;
	background-color: ${color.BG0};
	${(props) => (props.boxShadow ? 'box-shadow: 0 0 10px #111166' : '')};
`;

const containerPadding = '4%';

export const SurveyCardContainer = styled.div`
	width: calc(100% - ${containerPadding} * 2);
	height: calc(100% - ${containerPadding} * 2);
	padding: ${containerPadding};
	display: flex;
	flex-direction: column;
`;
