import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDistributions } from '../../../services/APIservice';
import BarChart from '../charts/BarChart';
import { color } from '../../../Styles';
import { DistributionsTitle } from './styles';

const Distributions = ({ surveyId, surveyContent, setErrorMessage }) => {
	const [content, setContent] = useState({});
	const [selected, setSelected] = useState('');
	useEffect(() => {
		getDistributions(surveyId)
			.then((response) => {
				const temp = surveyContent.sections.reduce(
					(object, section) => {
						let sectionContent = [];
						sectionContent.push(
							<h3 key={section.title}>{section.title}</h3>,
						);
						section.questions.forEach((question) => {
							sectionContent.push(
								<h4 key={section.id + '-' + question.id + '-q'}>
									{question.question}
								</h4>,
							);
							sectionContent.push(
								<BarChart
									key={section.id + '-' + question.id}
									content={
										response.data[
											section.id + '-' + question.id
										]
									}
								/>,
							);
						});

						object = {
							...object,
							[section.title]: sectionContent,
						};

						return object;
					},
					{},
				);

				setContent(temp);
				setSelected(Object.keys(temp)[0]);
			})
			.catch((error) => {
				setErrorMessage(error.response);
			});
	}, [surveyId, setErrorMessage, surveyContent.sections]);

	if (!selected) {
		return '';
	}

	const handleClick = (selection) => {
		setSelected(selection);
	};

	const style = {
		display: 'inline-block',
		padding: '5px',
		cursor: 'pointer',
	};

	const styleForSelected = {
		...style,
		color: color.BG0,
		borderRadius: '5px',
		backgroundColor: color.primary,
	};

	const menu = Object.keys(content).map((key) => (
		<div
			key={key}
			id={key}
			onClick={() => handleClick(key)}
			style={selected === key ? styleForSelected : style}
		>
			{key}
		</div>
	));

	return (
		<>
			<DistributionsTitle>Vastausten jakaumat</DistributionsTitle>
			{menu}
			{content?.[selected]}
		</>
	);
};

const mapStateToProps = (state) => ({
	surveyContent: state.survey.languages[0],
});

export default connect(mapStateToProps)(Distributions);
