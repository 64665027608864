import React, { useEffect, useState } from 'react';
import { AppContainer, AppDiv } from '../../../Styles';
import { connect } from 'react-redux';

import SurveyCard from '../../shared/SurveyCard';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import {
	ButtonArrow,
	ButtonDiv,
	MotiLogo,
	MotiLogoDiv,
	MotiWelcomeVideo,
	StartSurveyButton,
	StartSurveyButtonSpan,
	WelcomeText,
	WelcomeTextDiv,
	WelcomeTitle,
	MotiWelcomeVideoContainer,
	WelcomeTitleDiv,
} from './styles';
import MOTI_logo from '../../../assets/images/COF_Moti_Logo.svg';
import ArrowRight from '../../../assets/images/arrow_right_blue.png';
import WelcomeVideoFinSub from '../../../assets/videos/MOTI_tyontekijalle_sub.mp4';
import WelcomeVideoEngSub from '../../../assets/videos/MOTI_tyontekijalle_sub_ENG.mp4';
import WelcomeVideoSweSub from '../../../assets/videos/MOTI_tyontekijalle_sub_SWE.mp4';
import WelcomeVideoThumbnailFin from '../../../assets/images/moti-vid-fin.png';
import WelcomeVideoThumbnailSwe from '../../../assets/images/moti-vid-swe.png';
import WelcomeVideoThumbnailEng from '../../../assets/images/moti-vid-eng.png';

import DropDownComponent from '../../shared/DropDown';
import { setLanguage } from '../../../redux/actions';

const StartSurvey = ({ language, setLanguage }) => {
	const answerId = useParams().answerId;
	const history = useHistory();

	const videoLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return WelcomeVideoSweSub;
			case 'Suomi':
				return WelcomeVideoFinSub;

			default:
				return WelcomeVideoEngSub;
		}
	};

	const thumbnailLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return WelcomeVideoThumbnailSwe;
			case 'Suomi':
				return WelcomeVideoThumbnailFin;

			default:
				return WelcomeVideoThumbnailEng;
		}
	};

	const renderTitle = () => {
		switch (language.language) {
			case 'Svenska':
				return (
					<WelcomeTitle>
						Välkommen att göra en
						<br />
						motivations enkät!
					</WelcomeTitle>
				);
			case 'Suomi':
				return (
					<WelcomeTitle>
						Tervetuloa tekemään
						<br />
						Moti-motivaatiokysely!
					</WelcomeTitle>
				);

			default:
				return (
					<WelcomeTitle>
						Welcome to take a
						<br />
						motivational survey!
					</WelcomeTitle>
				);
		}
	};

	const welcomeParagraphLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return (
					<WelcomeText>
						Välkommen att göra en fyra minuters motivationsenkät!
						<br />
						De följande fyra frågorna hjälper dig att bedöma hur
						motiverad du är i ditt arbete.
					</WelcomeText>
				);
			case 'Suomi':
				return (
					<WelcomeText>
						Tervetuloa neljän minuutin motivaatiokyselyyn!
						<br />
						Katsomalla yllä näkyvän videon ja vastaamalla neljään
						kysymykseen saat tärkeää tietoa omasta
						työmotivaatiostasi
					</WelcomeText>
				);

			default:
				return (
					<WelcomeText>
						Welcome to take a four-minute motivation survey, Moti!
						<br />
						The following four questions will help you assess how
						motivated you are in your work.
					</WelcomeText>
				);
		}
	};

	const startButtonLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Starta enkäten';
			case 'Suomi':
				return 'Aloita kysely';

			default:
				return 'Start the survey';
		}
	};

	return (
		<SurveyCard>
			<MotiLogoDiv>
				<MotiLogo src={MOTI_logo} />
			</MotiLogoDiv>
			<WelcomeTitleDiv>{renderTitle()}</WelcomeTitleDiv>
			<ButtonDiv>
				<DropDownComponent />
			</ButtonDiv>
			<MotiWelcomeVideoContainer>
				<MotiWelcomeVideo
					key={language.language}
					width={'100%'}
					autoplay
					controls
					poster={thumbnailLanguage()}
				>
					<source src={videoLanguage()} />
				</MotiWelcomeVideo>
			</MotiWelcomeVideoContainer>
			<WelcomeTextDiv>{welcomeParagraphLanguage()}</WelcomeTextDiv>
			<ButtonDiv>
				<StartSurveyButton
					onClick={() => history.push(`/survey/${answerId}`)}
				>
					<StartSurveyButtonSpan>
						{startButtonLanguage()}
					</StartSurveyButtonSpan>
					<ButtonArrow src={ArrowRight} />
				</StartSurveyButton>
			</ButtonDiv>
		</SurveyCard>
	);
};

const mapDispatchToProps = {
	setLanguage,
};

const mapStateToProps = (state) => ({
	language: state.language,
});
export default connect(mapStateToProps, mapDispatchToProps)(StartSurvey);
