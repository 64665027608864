import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setToken } from './redux/actions';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import Survey from './components/survey/Survey';
import AnswererSummary from './components/results/AnswerSummary';
import SurveyResults from './components/results/SurveyResults';
import CreateSurvey from './components/admin/CreateSurvey';
import Login from './components/admin/Login';
import LandingPage from './components/admin/LandingPage';
import './app.css';
import Signup from './components/admin/Signup';
import { AppContainer, AppDiv } from './Styles';
import Footer from './components/shared/Footer';
import BG1 from './assets/images/cof_moti_BG1.jpg';
import BG2 from './assets/images/cof_moti_BG2.jpg';
import StartSurvey from './components/survey/StartSurvey';
import Error404 from './components/survey/Error404';

const App = ({ token, setToken }) => {
	useEffect(() => {
		const token = window.localStorage.getItem('token');
		if (token) {
			setToken({ token });
		}
	});

	return (
		<Router>
			<Switch>
				<Route exact path="/survey/new">
					{token ? (
						<AppDiv src={BG1}>
							<AppContainer>
								<CreateSurvey />
								<Footer />
							</AppContainer>
						</AppDiv>
					) : (
						<Redirect to="/login" />
					)}
				</Route>
				<Route path="/survey/:answerId">
					<AppDiv src={BG2}>
						<AppContainer>
							<Survey />
							<Footer />
						</AppContainer>
					</AppDiv>
				</Route>
				<Route path={'/survey'}>
					<Redirect to={'/404'} />
				</Route>

				<AppDiv id={'Background'} src={BG1}>
					<AppContainer id={'Container'}>
						<Route exact path="/">
							{token ? <LandingPage /> : <Redirect to="/login" />}
						</Route>
						<Route exact path={'/welcome/:answerId'}>
							<StartSurvey />
						</Route>
						<Route exact path={'/welcome'}>
							<Redirect to={'/404'} />
						</Route>
						<Route path="/summary/:answerId">
							<AnswererSummary />
						</Route>
						<Route path="/results/:surveyId">
							{token ? (
								<SurveyResults />
							) : (
								<Redirect to="/login" />
							)}
						</Route>
						<Route path="/login">
							{!token ? <Login /> : <Redirect to="/" />}
						</Route>
						<Route path="/signup">
							{!token ? <Signup /> : <Redirect to="/" />}
						</Route>
						<Route path="/404">
							<Error404 />
						</Route>
						<Footer />
					</AppContainer>
				</AppDiv>
				<Route path="*">
					<div className="container">
						<h1
							style={{
								textAlign: 'center',
								marginTop: '20%',
							}}
						>
							404 - Hakemaasi sivua ei löytynyt
						</h1>
					</div>
				</Route>
			</Switch>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	token: state.token,
});

const mapDispatchToProps = {
	setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
