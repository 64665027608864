import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'

const DoughnutChart = ({ answered, notAnswered, minimum }) => {
    
    const ref = useRef(null)

    // Number of answers still needed to show results
    const needed = Math.max(minimum - answered, 0)

    useEffect(() => {
        new Chart(ref.current, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: 'testi',
                    data: [answered, needed, notAnswered - needed],

                    backgroundColor: [
                        'rgba(3, 99, 255, 0.5)',
                        'rgba(214, 36, 24, 0.5)',
                        'rgba(187, 187, 187, 0.2)',
                    ],
                    borderColor: [
                        'rgba(3, 99, 255, 1)',
                        'rgba(214, 36, 24, 1)',
                        'rgba(187, 187, 187, 1)',
                    ],
                }],
            },

            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            }
        })
    }, [ref, answered, needed, notAnswered])

    return (
        <canvas ref={ ref }/>
    )
}

export default DoughnutChart