import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useSpring } from 'react-spring';
import BlueRightArrow from '../../../../../assets/images/arrow_right_blue.png';
import {
	ArrowIcon,
	ExpandFeedBackDiv,
	FeedBackContent,
	FeedbackDiv,
} from './styles';
import { toggleExpandable } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const ExpandableFeedback = ({
	answer,
	question,
	expanded,
	id,
	toggleExpandable,
	language,
}) => {
	const feedback = useRef();

	const feedbackLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'RESPONS';
			case 'Suomi':
				return 'PALAUTE';

			default:
				return 'FEEDBACK';
		}
	};

	const questionLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'VAD SKULLE DU SVARA PÅ DESSA FRÅGOR?';
			case 'Suomi':
				return 'MITÄ VASTAISIT NÄIHIN KYSYMYKSIIN?';

			default:
				return 'CONSIDER THE FOLLOWING QUESTIONS';
		}
	};

	let expandDiv = useSpring({
		maxHeight: expanded
			? `${feedback.current?.offsetHeight || 1000}px`
			: '0px',
	});

	useEffect(() => {
		if (!expanded) {
			toggleExpandable({ id });
		}
	}, [feedback.current]);

	let result = '';
	if (answer < 3) {
		result = 'low';
	} else if (answer < 6) {
		result = 'mid';
	} else {
		result = 'high';
	}

	const handleToggle = () => {
		toggleExpandable({ id });
	};

	return (
		<FeedbackDiv>
			<ExpandFeedBackDiv onClick={handleToggle}>
				<p>{feedbackLanguage()}</p>
				{/*<ArrowIcon src={BlueRightArrow} expanded={expanded} />*/}
			</ExpandFeedBackDiv>

			<FeedBackContent>
				<div ref={feedback}>
					<p>{question.feedback[result].content}</p>
					<h2>{questionLanguage()}</h2>
					<ul>
						{question.feedback[result].followUpQuestions.map(
							(fq) => (
								<li key={fq}>{fq}</li>
							),
						)}
					</ul>
				</div>
			</FeedBackContent>
		</FeedbackDiv>
	);
};

const mapStateToProps = (state, ownProps) => ({
	expanded: state.expanded[ownProps.id],
	language: state.language,
});

const mapDispatchToProps = {
	toggleExpandable,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableFeedback);
