import styled from 'styled-components';
import { color, cursor, font, units } from '../../../Styles';

export const SurveyListRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	justify-content: space-between;
`;

export const NewSurveyItem = styled.div`
	width: 100%;
	background-color: ${color.BG1};
	box-shadow: 0 0 5px ${color.BG2};
	height: 50px;
	${cursor.clickable};
	display: flex;
	&:hover {
		background-color: ${color.BG1};
		box-shadow: 0 0 10px ${color.BG2};
		transform: scale(1.001);
		transition: all 0.2s linear;
	}
`;

export const NewSurveyItemLabel = styled.span`
	${font.DCBold};
	padding-top: 4px;
	color: ${color.primary};
	font-size: 24px;
	margin: auto;
`;

export const LandingPageHeader = styled.h1`
	${font.PBReg};
	font-weight: lighter;
	color: ${color.primary};
`;

export const SurveysHeader = styled.h2`
	${font.PBReg};
	font-weight: lighter;
`;

export const ButtonRow = styled.div`
	display: flex;
	width: 100%;
	margin-top: 20px;
	& button {
		&:hover {
			color: ${color.primaryShade};
		}
	}
`;
