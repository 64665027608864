import React from 'react'
import { connect } from 'react-redux'
import { toggleExpandable } from '../../../redux/actions'
import './expandableContent.css'

const ExpandableContent = ({ id, content, expanded, toggleExpandable }) => {
    return (
        <div className='expandableContainer'>
            <div className='clickToToggle' onClick={() => toggleExpandable({ id })}>
                <h4 className='toggleText'>Palaute </h4>
                <svg id={`${id}-svg`} className='toggleImage' width="20" height="20" style={{transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)'}}>
                    <circle cx='10' cy='10' r='8' stroke='black' strokeWidth='2' fill='white'/>
                    <polygon points="5,5 10,5 15,10 10,15 5,15 10,10"/>
                </svg>
            </div>
            <div className='toggleContent' style={{display: expanded ? 'inline' : 'none'}}>
                { content }
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    expanded: state.expanded[ownProps.id]
})

const mapDispatchToProps = {
    toggleExpandable
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpandableContent)