import styled from 'styled-components';
import { color, font, units, cursor } from '../../../Styles';

export const RadioButtonContainer = styled.div`
	display: table-cell;
`;

export const Label = styled.span``;

const buttonSize = '8vw';

export const LabelTitle = styled.span`
	${font.DCBold};
	font-size: 24px;
	position: relative;
	top: calc(-10px - 30px);
	@media (max-width: ${units.tablet}) {
		top: calc(-12px - calc(${buttonSize} / 2));
		font-size: 20px;
	}
`;

export const Radio = styled.label`
	cursor: ${cursor.clickable};
	line-height: 25px;
	display: block;
	margin: 0 auto;
	height: 60px;
	width: fit-content;
	text-align: center;
	color: ${color.primary};
	@media (max-width: ${units.tablet}) {
		height: ${buttonSize};
	}
	&:hover {
		${Label}:after {
			transform: scale(1.6);
		}
	}
	& ${LabelTitle} {
	}
	& ${Label} {
		position: relative;
		display: block;
		float: left;
		width: 60px;
		height: 60px;
		background-color: ${color.BG0};
		border: 2px solid ${color.primary};
		border-radius: 100%;
		-webkit-tap-highlight-color: transparent;
		@media (max-width: ${units.tablet}) {
			width: ${buttonSize};
			height: ${buttonSize};
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 60px;
			height: 60px;

			border-radius: 100%;
			background: ${color.primary};
			transform: scale(0);
			transition: all 0.2s ease;
			opacity: 0.08;
			pointer-events: none;
			@media (max-width: ${units.tablet}) {
				width: ${buttonSize};
				height: ${buttonSize};
			}
		}
	}
	& .hidden {
		display: none;
	}
	& input[type='radio']:checked ~ ${LabelTitle} {
		color: ${color.BG0}!important;
	}
	& input[type='radio']:checked + ${Label}:after {
		transform: scale(1);
		transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
		opacity: 1;
	}
`;
