import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomButton from '../../survey/CustomButton';
import { expandAll, printFinished } from '../../../redux/actions';
import './PDFDownloader.css';
import Pdf from 'react-to-pdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadPDFButton } from './styles';

const PDFDownloader = ({
	expandAll,
	readyToPrint,
	printFinished,
	printRef,
	language,
}) => {
	const id = 'to-print';

	useEffect(() => {}, [readyToPrint, printFinished]);

	const printPdfLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'LADDA NER RESPONSEN SOM PDF';
			case 'Suomi':
				return `LATAA PALAUTTEET PDF-TIEDOSTONA`;
			default:
				return `DOWNLOAD THE FEEDBACK IN PDF-FORMAT`;
		}
	};

	const pxToMm = (px) => {
		return Math.floor(px / document.getElementById('myMm').offsetHeight);
	};

	const mmToPx = (mm) => {
		return document.getElementById('myMm').offsetHeight * mm;
	};

	const range = (start, end) => {
		return Array(end - start)
			.join(0)
			.split(0)
			.map(function (val, id) {
				return id + start;
			});
	};

	const a4WidthMm = 210;
	const a4HeightMm = 297;
	const dnload = () => {
		window.print();
	};
	const download = () => {
		let scale = 'scale(1)';
		document.body.style.webkitTransform = scale; // Chrome, Opera, Safari
		document.body.style.msTransform = scale; // IE 9
		document.body.style.transform = scale;
		const input = document.getElementById(id);
		const inputHeightMm = pxToMm(printRef.current.offsetHeight);
		const a4HeightPx = mmToPx(a4HeightMm);
		const numPages =
			inputHeightMm <= a4HeightMm
				? 1
				: Math.floor(inputHeightMm / a4HeightMm) + 1;

		console.log({
			input,
			inputHeightMm,
			inputWidthMm: pxToMm(input.offsetWidth),
			a4HeightMm,
			a4HeightPx,
			a4WidthMm,
			a4WidthPx: mmToPx(a4WidthMm),
			numPages,
			range: range(0, numPages),
			comp: inputHeightMm <= a4HeightMm,
			inputHeightPx: input.offsetHeight,
			inputWidthPX: input.offsetWidth,
		});
		let browserZoomLevel = 1;
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
		) {
			console.log('Mobile detected');
			// to make the pdf look right on mobile devices we have to stretch the length according to the browser zoom level for some reason....
			browserZoomLevel = window.devicePixelRatio;
		} else {
			browserZoomLevel = 1;
		}
		console.log(browserZoomLevel);
		html2canvas(input, { scale: 1 }).then((canvas) => {
			try {
				const imgData = canvas.toDataURL('image/png');
				// let win = window.open();
				// win.document.write('<img src="' + imgData + '" />');
				//Document of a4WidthMm wide and inputHeightMm high
				let pdf;
				if (inputHeightMm > a4HeightMm) {
					// elongated a4 (system print dialog will handle page breaks)
					pdf = new jsPDF('p', 'mm', [
						inputHeightMm * browserZoomLevel,
						a4WidthMm,
					]);
				} else {
					// standard a4
					pdf = new jsPDF();
				}
				console.log(printRef.current);
				const imgWidth = pdf.internal.pageSize.getWidth();
				const imgHeight = pdf.internal.pageSize.getHeight();

				pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
				console.log(navigator.userAgent);

				pdf.save('MOTI_survey_results.pdf');
			} catch (e) {
				console.log(e);
				alert('Download blocked, please allow pop-ups.');
			}
		});
	};
	console.log(printRef);
	return (
		<div
			style={{
				textAlign: 'center',
			}}
		>
			<div id="myMm" style={{ height: '1mm' }} />

			<DownloadPDFButton onClick={download}>
				{printPdfLanguage()}
			</DownloadPDFButton>
		</div>
	);
};

const mapStateToProps = (state) => ({
	readyToPrint: state.readyToPrint,
	language: state.language,
});

const mapDispatchToProps = {
	expandAll,
	printFinished,
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFDownloader);
