import axios from 'axios';
import store from './../redux/store';

const instance = axios.create({
	baseURL: `${
		process.env.NODE_ENV === 'production'
			? 'https://api-dot-moti-291818.appspot.com'
			: 'http://localhost:3001'
	}/api`,
});

export const getAnswer = (surveyId) => instance.get(`/answer/${surveyId}`);

export const postAnswer = (surveyId, data) =>
	instance
		.post(`/answer/${surveyId}`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((response) => response.data);

export const getDistributions = (surveyId) =>
	instance.get(`/results/distributions/${surveyId}`, {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const getAverages = (key, value) =>
	instance
		.get(`/results/averages?${key}=${value}`, {
			headers: {
				Authorization: `Bearer ${store.getState().token}`,
			},
		})
		.then((response) => response.data);

export const getMedians = (key, value) =>
	instance
		.get(`/results/median?${key}=${value}`, {
			headers: {
				Authorization: `Bearer ${store.getState().token}`,
			},
		})
		.then((response) => response.data);

export const getAveragesWithFilter = (surveyId, filter) =>
	instance.get(`/results/averages?surveyId=${surveyId}&filter=${filter}`, {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const getMediansWithFilter = (surveyId, filter) =>
	instance.get(`/results/median?surveyId=${surveyId}&filter=${filter}`, {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const getSurveys = () =>
	instance.get('/survey', {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const createSurvey = (data) =>
	instance.post(`/survey`, data, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const deleteSurvey = (surveyId) =>
	instance.delete(`/survey/${surveyId}`, {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const getSurveyNameAndFilters = (surveyId) =>
	instance.get(`/survey/${surveyId}`, {
		headers: {
			Authorization: `Bearer ${store.getState().token}`,
		},
	});

export const signup = (email, password) =>
	instance.post(
		'/users',
		{ email, password },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);

export const login = (email, password) =>
	instance.post(
		'/users/login',
		{ email, password },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);
