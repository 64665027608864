import { createStore } from 'redux';
import reducer from './reducer';

const store = createStore(reducer);

// eslint-disable-next-line no-unused-vars
const subscribe = () =>
	store.subscribe(() => {
		console.log(store.getState());
	});

// Uncomment the line below to console.log state on change.
// subscribe()

export default store;
