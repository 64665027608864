import React from 'react';
import { connect } from 'react-redux';
import CustomRadioButton from '../CustomRadioButton';
import FeedBack from '../Feedback';
import {
	MaxEndpoint,
	MinEndpoint,
	OptionContainer,
	OptionStrikeThrough,
	Question,
} from './styles';

const Likert = ({ question, section, customStyles, id, language }) => {
	const minEndpointLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Inte alls';
			case 'Suomi':
				return 'En lainkaan';

			default:
				return 'Not at all';
		}
	};

	const maxEndpointLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Väldigt mycket';
			case 'Suomi':
				return 'Erittäin paljon';

			default:
				return 'Very much';
		}
	};

	const options = [...Array(7).keys()].map((option) => (
		<CustomRadioButton
			key={option + 1}
			value={option + 1}
			group={section}
			questionId={id}
			customStyles={customStyles}
		/>
	));

	return (
		<div>
			<p className="likertInfo">{question.info}</p>
			<Question>
				<strong>{question.question}</strong>
			</Question>
			<OptionStrikeThrough />
			<OptionContainer className={'options'}>{options}</OptionContainer>
			<MinEndpoint>{minEndpointLanguage()}</MinEndpoint>
			<MaxEndpoint>{maxEndpointLanguage()}</MaxEndpoint>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	answer: state.answers[ownProps.section + '-' + ownProps.id],
	language: state.language,
});

export default connect(mapStateToProps)(Likert);
