import styled, { css } from 'styled-components';
import Color from 'color';

const baseColor = '#ffffff';

export const colorAdjust = {
	darken: (color, amount) => Color(color).darken(amount).hex(),
	lighten: (color, amount) => Color(color).lighten(amount).string(),
	rgba: (color, opacity) => Color(color).alpha(opacity).string(),
};

export const units = {
	margin: '10px',
	radius: '4px',
	tablet: '1000px',
	mobile: '600px',
};

export const color = {
	primary: '#0064FF',
	primaryShade: colorAdjust.darken('#0064FF', 0.2),
	secondary: '#FF684F',
	secondaryShade: colorAdjust.darken('#FF684F', 0.2),
	tertiary: '#FFE8D8',
	tertiaryShade: colorAdjust.darken('#FFE8D8', 0.1),
	text: '#000000',
	header: '#0E274D',
	BG0: baseColor,
	BG1: '#f3f3f3',
	BG2: '#b9b9b9',
};

export const font = {
	DCBold: css`
		font-family: din-condensed-bold, sans-serif;
	`,
	VItalic: css`
		font-family: volkhov-italic, serif;
		font-style: italic;
	`,
	RReg: css`
		font-family: roboto-regular, sans-serif;
	`,
	RBold: css`
		font-family: roboto-bold, sans-serif;
	`,
	BBold: css`
		font-family: 'Libre Baskerville', serif;
	`,
	PBBold: css`
		font-family: roboto-bold, sans-serif;
	`,
	PBReg: css`
		font-family: Paperback, sans-serif;
	`,
	PBItalic: css`
		font-family: volkhov-italic, serif;
		font-style: italic;
	`,
	title: css`
		color: ${color.primary};
		font-size: 40px;
		font-family: din-condensed-bold, sans-serif;
		letter-spacing: 2px;
		text-transform: uppercase;
		@media (max-width: ${units.mobile}) {
			font-size: calc(3vw + 20px);
		}
	`,
};

export const cursor = {
	clickable: css`
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		cursor: pointer;
	`,
	draggable: css`
		cursor: grab;
		user-select: none;
	`,
	dragging: css`
		cursor: grabbing;
	`,
	notAllowed: css`
		cursor: not-allowed;
		user-select: none;
	`,
};

export const components = {
	animatedLabeledInput: css`
		position: relative;
		overflow: hidden;
		height: 66px;
		& input {
			width: 100%;
			height: 100%;
			color: ${color.primary};
			padding-top: 20px;
			border: none;
			outline: none;
			&:focus + label::after {
				transform: translateX(0%);
			}
			&:valid + label::after {
				transform: translateX(0%);
			}
			&:focus + label span {
				transform: translateY(-100%);
			}
			&:valid + label span {
				transform: translateY(-100%);
			}
			&:-webkit-autofill {
				background-color: ${color.BG0};
				-webkit-box-shadow: 0 0 0 1000px ${color.BG0} inset;
			}
			&:-webkit-autofill + label span {
				transform: translateY(-100%);
			}
		}

		& label {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			border-bottom: 2px solid ${color.BG2};
		}

		& label::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			bottom: -2px;
			transition: all 0.3s ease;
			border-bottom: 2px solid ${color.primary};
			transform: translateX(-100%);
		}
		& label span {
			position: absolute;
			bottom: 5px;
			${font.DCBold};
			color: ${color.text};
			font-size: 18px;
			transition: all 0.3s ease;
		}
	`,
	buttonWhite: css`
		padding: 2px 18px;
		letter-spacing: 1px;
		${font.DCBold};
		height: 36px;
		text-transform: uppercase;
		font-size: 20px;
		border-radius: 4px;
		line-height: 36px;
		background-color: ${color.BG0};
		${cursor.clickable};
		border: 2px solid ${color.primary};
		transition: background-color 0.1s;
		color: ${color.primary};
		& a {
			text-decoration: none;
			&:visited {
				color: ${color.primary};
			}
		}
		&:focus {
			outline: none;
		}
		&:hover {
			background-color: ${colorAdjust.darken(color.BG0, 0.1)};
		}
		&:disabled {
			color: ${color.primary}90;
			border-color: ${color.primary}90;
			${cursor.notAllowed};
		}
		&:disabled:hover {
			color: ${color.primary}90;
			border-color: ${color.primary}90;
			${cursor.notAllowed};
			background-color: white;
		}
	`,
};

export const AppDiv = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	overflow-y: auto;
	//background-color: ${color.BG1};
	background: url(${(props) => props.src});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const AppContainer = styled.div`
	width: 80%;
	max-width: 800px;
	background-color: ${color.BG1}00;
	margin: 5% auto auto auto;
	display: flex;

	flex-direction: column;
	@media (max-width: ${units.mobile}) {
		width: 90%;
		margin: 5% 5% auto 5%;
	}
`;
