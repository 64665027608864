import styled, { css } from 'styled-components';
import { color, font, units } from '../../../../../Styles';
import { animated } from 'react-spring';

export const CustomLineChartContainer = styled.div`
	width: 100%;
`;

export const CustomLineChartOptionDiv = styled.div`
	width: 100%;
	display: flex;
`;

export const CustomLineChartStrikeThrough = styled.div`
	width: 86%;
	height: 2px;
	margin: 0 auto;
	top: calc(33px);
	z-index: 1;
	background-color: ${color.primary};
	position: relative;
	@media (max-width: ${units.tablet}) {
		top: calc(3px + 3vw);
	}
`;

export const OptionLabel = styled.span`
	position: relative;
	top: calc(50% - 8px);
	width: fit-content;
	${font.DCBold};
	font-size: 20px;
	display: none;
	left: calc(50% - 4px);
`;

export const OptionTitle = styled.div`
	text-transform: uppercase;
	${font.DCBold};
	font-size: 14px;
	color: ${(props) => (props.average ? color.BG2 : color.primary)};
	position: relative;
	top: 50px;
	width: 60px;
	text-align: center;
	@media (max-width: ${units.tablet}) {
		display: none;
	}
`;

export const MobileStatSummary = styled.p`
	${font.PBItalic};
	font-size: 18px;
	margin: 0.5em 0;
	display: none;
	@media (max-width: ${units.tablet}) {
		display: block;
	}
`;

export const AnsweredOption = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 2px solid ${color.primary};
	margin: auto;
	position: relative;
	z-index: 2;
	text-align: center;
	background-color: ${color.primary};
	@media (max-width: ${units.tablet}) {
		width: 6vw;
		height: 6vw;
	}
	& ${OptionLabel} {
		color: ${color.BG0};
		display: block !important;
	}
`;

export const AverageOption = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 2px solid ${color.primary};
	margin: auto;
	position: relative;
	z-index: 2;
	background-color: ${color.BG0};
	@media (max-width: ${units.tablet}) {
		width: 6vw;
		height: 6vw;
	}
	& ${OptionLabel} {
		color: ${color.primary};
		display: block !important;
	}
`;

export const OptionDivContainer = styled.div`
	width: 60px;
	height: 60px;
	display: flex;
	margin: auto;
	@media (max-width: ${units.tablet}) {
		width: 6vw;
		height: 6vw;
	}
`;

export const OptionDiv = styled.div`
	border-radius: 50%;
	border: 2px solid ${color.primary};
	margin: auto;
	position: relative;
	z-index: 2;
	height: 10px;
	width: 10px;
	background-color: ${color.BG0};
`;
