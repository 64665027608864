import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import Section from '../Section';
import CustomButton from '../CustomButton';
import { getAnswer } from '../../../services/APIservice';
import { setAllAnswers } from '../../../redux/actions';

import './survey.css';
import {
	CurrentQuestionDiv,
	NavigationRowDiv,
	NextButtonDiv,
	PrevButtonDiv,
	SurveyQuestionDiv,
} from './styles';
import SurveyCard from '../../shared/SurveyCard';
import Footer from '../../shared/Footer';
import { AppContainer, AppDiv } from '../../../Styles';
import DropDownComponent from '../../shared/DropDown';

const Survey = ({
	surveyContent,
	customStyles,
	sent,
	setAllAnswers,
	language,
	answers,
}) => {
	const answerId = useParams().answerId;
	const history = useHistory();
	const [surveyFound, setSurveyFound] = useState('loading');
	const [active, setActive] = useState(0);

	const nextButtonLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Nästa';
			case 'Suomi':
				return 'Seuraava';

			default:
				return 'Next';
		}
	};

	const previousButtonLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Föregående';
			case 'Suomi':
				return 'Edellinen';

			default:
				return 'Previous';
		}
	};

	const sendButtonLanguage = () => {
		switch (language.language) {
			case 'Svenska':
				return 'Skicka';
			case 'Suomi':
				return 'Lähetä';

			default:
				return 'Send';
		}
	};

	useEffect(() => {
		getAnswer(answerId)
			.then((response) => {
				setSurveyFound(response.data);
			})
			.catch((error) => {
				setSurveyFound('404');
			});
	}, [answerId]);

	if (surveyFound === 'loading') {
		return <SurveyCard>loading</SurveyCard>;
	} else if (surveyFound === '404') {
		return <Redirect to={'/404'} />;
	} else if (surveyFound !== '') {
		setAllAnswers({ answersJSON: surveyFound });
		return <Redirect to={`/summary/${answerId}`} />;
	}

	const next = () => {
		if (active < 3) {
			setActive(active + 1);
		}
	};

	const previous = () => {
		if (active > 0) {
			setActive(active - 1);
		} else if (active === 0) {
			history.push(`/welcome/${answerId}`);
		}
	};
	const sections = surveyContent.languages[
		language.id
	].sections.map((s, index) => (
		<Section
			key={s.title}
			data={s}
			active={active === index}
			customStyles={customStyles}
		/>
	));

	console.log(Object.keys(answers));
	console.log(navigator.language);

	return sent ? (
		<Redirect to={`/summary/${answerId}`} />
	) : (
		<SurveyCard>
			{sections}
			<NavigationRowDiv>
				<PrevButtonDiv>
					{active >= 0 ? (
						<CustomButton
							id="previous"
							onClick={previous}
							text={previousButtonLanguage()}
						/>
					) : (
						''
					)}
				</PrevButtonDiv>
				<CurrentQuestionDiv>
					{active + 1}/{sections.length}
				</CurrentQuestionDiv>
				<NextButtonDiv>
					{active < 3 ? (
						<CustomButton
							id="next"
							disabled={!answers[Object.keys(answers)[active]]}
							onClick={next}
							text={nextButtonLanguage()}
						/>
					) : (
						''
					)}
					{active >= 3 ? (
						<CustomButton
							id="send"
							text={sendButtonLanguage()}
							data={{ answerId }}
						/>
					) : (
						''
					)}
				</NextButtonDiv>
			</NavigationRowDiv>
		</SurveyCard>
	);
};

const mapStateToProps = (state) => ({
	surveyContent: state.survey,
	sent: state.sent,
	answers: state.answers,
	language: state.language,
});

const mapDispatchToProps = {
	setAllAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
